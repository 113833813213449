import CheckoutCard from "./CheckoutCard";
import galaxisLogo from "../../../assets/images/galaxis.svg";
import { Box, Typography, Button } from "@mui/material";

const sx = {
  root: {
    position: "relative",
    background: "rgb(250,249,245)",
    width: "100%",
    height: "100%",
    zIndex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
    maxWidth: "350px",
    mx: "auto",
  },
  termsText: {
    fontFamily: "bau",
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "left",
  },
  logo: {
    position: "relative",
    height: "50px",
    mr: "auto",
    ml: 10,
    zIndex: 2,
    mt: 5,
    mb: 1,
  },
  backBtn:{
    color: "#FF692B",
    border: "solid 2px #FF692B",
    fontFamily: "poppins",
    fontWeight: "bold",
    fontSize: "20px",
    padding: "10px 35px",
    margin: "25px",
  }
};

const GalaxisTerms = ({
  setShow,
}) => {
  return (
    <Box sx={sx.root}>
      <Box>
        <Box component="a" href="https://galaxis.xyz" target="_blank">
          <Box component="img" src={galaxisLogo} sx={sx.logo}></Box>
        </Box>
      </Box>
      <CheckoutCard
        header={"Terms and Conditions"}
        setOpen={setShow}
      >
        <Typography sx={sx.termsText}>
          UNLESS YOU OPT OUT, THESE TERMS AND CONDITIONS CONTAIN A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER THAT IMPACT YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES.
        </Typography>
        <br/>
        <Typography sx={sx.termsText}>
          These Terms and Conditions constitute a legally binding agreement (“Agreement”) between you ( “you” or “your”) and Akomba Labs PTE. LTD dba Galaxis (“Galaxis” or “we” or “us”) governing your use of our Platform. “Platform” means our proprietary web3 engagement platform in the limited context of its development, execution and maintenance of smart contracts in connection with the NBA Playoff NFTs. All capitalized terms used but not defined in this Agreement have the respective meanings assigned to them in the NFT Terms and Conditions (as defined below).
        </Typography>
        <br/>
        <Typography sx={sx.termsText}>
          BY YOUR ACCESS OF AND INTERACTION WITH THE PLATFORM, INCLUDING BY CLICKING “I AGREE TO THE TERMS & CONDITIONS,” “MINT,” OR SIMILAR CONFIRMATIONS OF THIS AGREEMENT AND/OR CALLS TO ACTION, YOU AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, DO NOT ACCESS OR INTERACT WITH THE PLATFORM. YOU MUST AGREE TO THE TERMS OF THIS AGREEMENT IN ORDER TO UTILIZE THE PLATFORM IN CONNECTION WITH THE NBA PLAYOFF NFTS.
        </Typography>
        <br/>
        <Typography sx={sx.termsText}>
          You understand, acknowledge and agree that the terms and conditions governing your NBA Playoff NFTs in connection with the 2022 Association NFT Collection being made available on the website located at <a href="http://TheAssociationNFT.com">http://TheAssociationNFT.com</a>​​ are as set forth in the NBA Properties, Inc. Terms and Conditions for NBA Playoff Non-Fungible Token: <a href="https://theassociationnft.com/nft-terms-and-conditions">https://theassociationnft.com/nft-terms-and-conditions</a> (the “NFT Terms and Conditions”). Galaxis is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with your use of or reliance on the NFT Terms and Conditions, the Site, and/or any content, advertising, products, services, information or other materials provided to you by the NBA Parties or any other third party in connection with the NBA Playoff NFTs, the Collection or the Site.
        </Typography>
        <br/>
        <Typography sx={sx.termsText}>
          Agreement to Arbitrate & Class Action Waiver; Your Indemnity Obligations: To help streamline the resolution of disputes, claims, and controversies under this Agreement, as set forth in more detail below, you agree that both you and our, including our present and future parents and subsidiaries, will be obligated to arbitrate disputes, claims, and controversies that arise out of or relate to your use of the Platform and/or the provision of content, services, and/or technology on or through the Platform, and that any such proceedings will be conducted only on an individual basis (and not as a class action). Accordingly, and in furtherance thereof, Sections 13, 14 and 15 of the NFT Terms and Conditions are hereby incorporated by reference, mutatis mutandis, as if fully set forth herein. You have the right to opt-out and not be bound by such arbitration provisions, solely with respect to this Agreement, by sending written notice of your decision to opt-out to the following address:NBA Account Relations, 645 Fifth Avenue, New York, NY 10022, Attn: Legal Department.
        </Typography>
        <br/>
        <Typography sx={sx.termsText}>
          THE PLATFORM IS PROVIDED “AS IS” AND ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING THOSE RELATED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, ARE HEREBY DISCLAIMED.  NEITHER GALAXIS NOR ITS AFFILIATES (OR EITHER OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, LICENSEES, LICENSORS, OR SUCCESSORS AND ASSIGNS) SHALL BE LIABLE TO YOU OR ANY OF YOUR AFFILIATES FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO (I) THE PLATFORM OR THIS AGREEMENT OR (II) THE NFT TERMS AND CONDITIONS, THE NBA PLAYOFF NFTS, THE COLLECTION OR THE SITE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND USING BLOCKCHAIN TECHNOLOGY AND YOU AGREE THAT GALAXIS HAS NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO GALAXIS’S GROSS NEGLIGENCE. IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW. PLEASE BE AWARE THAT THIS LIMITATION OF LIABILITY PROVISION APPLIES TO THE FULLEST EXTENT PERMISSIBLE BY LAW.
        </Typography>
        <br/>
        <Typography sx={sx.termsText}>
          Use of the Platform is open only to individuals who are over the age of eighteen (18) and have the right and authority to enter into this Agreement and who are fully able to satisfy the terms, conditions, and obligations herein.  Interaction with the Platform will be deemed a representation that you have the legal right to enter into a contract.  If you are under the age of eighteen (18) or otherwise cannot lawfully enter into a contract, you must have your parent or guardian authorize and enter into this Agreement.
        </Typography>
        <br/>
        <Typography sx={sx.termsText}>
          The Agreement shall be exclusively governed by, construed, and enforced in accordance with, the laws of the State of New York without regard to principles of conflicts of law. If you are provided a translation of this Agreement, the original version in English will be used in deciding any issues or disputes which arise under this Agreement. If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. This Agreement constitutes the entire agreement between you and Galaxis with respect to the matters provided for herein and is not intended to be modified or limited in any way by any other written instrument or oral agreement previously made or entered into by the parties hereto.  This Agreement is not intended and shall not be construed to create any rights or remedies in any parties other than you and Galaxis and its affiliates, and no other person shall assert any rights as a third-party beneficiary hereunder.
        </Typography>
        <Button sx={sx.backBtn}
            onClick={() => {
              if (setShow) {
                setShow(false);
              }
            }}>
              Back
        </Button>
      </CheckoutCard>
    </Box>
  );
};

export default GalaxisTerms;
