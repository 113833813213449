import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import config from '../../../../config/index';
import ExplorerCard from './ExplorerCard.jsx';
import { loadNext } from '../../../common/InfiniteScrollHelpers';
import { Box, Grid } from '@mui/material';
import { maxWidth } from '@mui/system';
import { SpinnerCircular } from 'spinners-react';
// import { Data } from '../../DataContext';

const sx = {
  cardBox: {
    mb: '36px',
  },
  cardHolder: {
    pt: '10px',
  },
};

const CardHolder = ({
  nfts,
  filtersAreActive,
  currentPage,
  _setCurrentPage,
  clearCardsArray,
}) => {
  const LAYER_SERVER = config.LAYER_SERVER;

  const ITEMS_PER_PAGE = 100;
  const [cards, setCards] = useState([]);
  const currentPageRef = useRef(currentPage);
  const [loading, setloading] = useState(false);
  const [smGrid, setSmGrid] = useState(6);
  const [number, setNumber] = useState(0);

  // useEffect(() => {
  //   console.log( 'elso render')

  // }, [])
  // console.log(nfts);
  useEffect(() => {
    if (clearCardsArray > 0) {
      setCards([]);
    }
  }, [clearCardsArray]);
  const setCurrentPage = (val) => {
    currentPageRef.current = val;
    _setCurrentPage(val);
  };

  // useEffect(()=>{
  //   loadNext(nfts, currentPageRef, _setCurrentPage, setCards, cards)
  // },[currentPageRef])
  // //   const { traitTypes } = useContext(Data);

  useEffect(() => {
    setloading(true);
    if (window.innerWidth < 700) {
      setSmGrid(12);
    }
    // window.scrollTo({
    //   top: '50%',
    //   left: 0,
    //   behavior: 'smooth'
    // });
    loadNext(nfts, currentPageRef, _setCurrentPage, setCards, cards);
    setloading(false);
  }, [nfts, currentPageRef]);

  // useEffect(() => {
  //   if (window.innerWidth < 700) {
  //     setSmGrid(12);
  //   }
  //   var hasMore = currentPage < cards.length / ITEMS_PER_PAGE ? true : false;

  //   console.log(currentPage, 'current page');
  //   console.log(cards.length, 'cards length', )
  //   console.log(hasMore, "has more")
  // }, [cards]);

  const RenderCards = () => {
    return cards.map((card, i) => {
      return (
        <Grid item key={i} sx={sx.cardBox} sm={smGrid} md={4}>
          <ExplorerCard card={card} />
        </Grid>
      );
    });
  };

  return (
    <>
      {loading ? (
        <SpinnerCircular />
      ) : (
        <Box>
          <InfiniteScroll
            dataLength={cards.length}
            next={
              () => {
                setCurrentPage(currentPageRef.current + 1);
              } // lekeri az nfts arraybe a dolgokat,
            }
            pullDownToRefreshThreshold={100}
            hasMore={nfts.length % ITEMS_PER_PAGE === 0 ? true : false}
            // loader={<h4>Loading...</h4>}
          >
            <Grid container spacing={3} sx={sx.cardHolder}>
              <RenderCards />
            </Grid>
          </InfiniteScroll>
        </Box>
      )}
    </>
  );
};

export default CardHolder;
