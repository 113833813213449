import { Box, Typography, Link } from "@mui/material";
import { useHistory } from "react-router-dom";
import nbaLogo from "../../../assets/images/nba-logo.svg";
import circularLogo from "../../../assets/images/circular-logo.svg"
import twitterLogo from "../../../assets/images/social/twitter.svg"
import discordLogo from "../../../assets/images/social/discordicon.svg"

const BP1 = "@media (max-width: 999px)";
const BP2 = "@media (max-width: 1024px)";

const sx = {
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    background: "#000000",
    height: "auto",
    [BP1]: {
      flexDirection: "column",
    }
  },
  container: {
    display: "flex",
    [BP1]: {
      padding: "20px",
      margin: "auto",
      textAlign: "center",
    },
  },
  linkContainer: {
    marginLeft: "auto",
    display: "flex",
    [BP1]: {
      margin: "auto",
      padding: "20px",
    }
  },
  footerImg1: {
    width: "30px",
    height: "68px",
    margin: "auto 0",
    marginLeft: "29px",
    [BP1]: {
      marginLeft: "0px",
      width: "50px",
      height: "100px",
    },
  },
  footerImg2: {
    width: "72px",
    height: "72px",
    margin: "auto",
    marginLeft: "10px",
    marginRight: "35px",
    [BP1]: {
      marginRight: "0px",
      width: "110px",
      height: "110px",
      marginLeft: "35px",
    },
  },
  footerImg3: {
    width: "30.38px",
    height: "23.55px",
    margin: "auto 0",
    marginRight: "26px",
    marginLeft: "40px",
    [BP1]: {
      marginLeft: "0px",
    },
  },
  footerImg4: {
    width: "39.56px",
    margin: "auto 0",
    marginRight: "89px",
    [BP1]: {
      marginRight: "0px",
    },
  },
  footerText: {
    color: "#FFFFFF",
    fontFamily: "action-nba",
    fontWeight: "700 bold",
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "1px",
    [BP2]: {
      fontSize: "18px",
    },
    [BP1]: {
      fontSize: "24px",
    }
  },
  footerTextLink: {
    margin: "auto 0",
    marginLeft: "16px",
    color: "#EFF0F5",
    fontFamily: "action-nba",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "1px",
    textDecoration: "underline",
    cursor: "pointer",
    [BP1]: {
      marginRight: "16px",
      marginLeft: "0px",
    },
  },
  copyRights: {
    margin: "auto",
  },
};

const Footer = () => {
  const history = useHistory();

  const handleNavigationLogo = (path) => {
    window.location.hash = '';
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
    if (location.pathname !== path) {
      history.push(path);
    }
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.container}>
        <Box component="img" sx={sx.footerImg1} src={nbaLogo}></Box>
        <Box component="img" sx={sx.footerImg2} src={circularLogo}></Box>
      </Box>
      <Box sx={sx.container}>
        <Box sx={sx.copyRights}>
          <Typography sx={sx.footerText}>
            © 2022 NBA Properties, Inc. All rights reserved.
          </Typography>
          <Typography sx={sx.footerText}>
            Officially licensed product of the National Basketball Players Association.
          </Typography>
        </Box>
      </Box>
      <Box sx={sx.linkContainer}>
        <Typography sx={sx.footerTextLink}>
          <Link href="https://www.nba.com/privacy-policy">
            PRIVACY POLICY
          </Link>
        </Typography>
        <Typography sx={sx.footerTextLink}>
          <Link href="https://www.nba.com/termsofuse">
            TERMS OF USE
          </Link>
        </Typography>
        <Typography sx={sx.footerTextLink}>
          <Link onClick={() => handleNavigationLogo('/nft-terms-and-conditions')}>
            NFT TERMS AND CONDITIONS
          </Link>
        </Typography>
      </Box>
      <Box sx={sx.container}>
        <Link href="https://twitter.com/nbaxnft" sx={sx.footerImg3}>
          <Box component="img" src={twitterLogo}></Box>
        </Link>
        <Link href="https://discord.gg/nba" sx={sx.footerImg4}>
          <Box component="img" src={discordLogo}></Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
