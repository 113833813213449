import { useState, useEffect, useCallback, useMemo } from "react";
import useWeb3Ctx from "./useWeb3Ctx";
import config from "../config";

const useAllowList = () => {
    const { address } = useWeb3Ctx();
    const [whitelistJson, setWhitelistJson] = useState(null);

    const fetchJSONDataFrom = useCallback(async (path) => {
        await fetch(path, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(async (res) => {
                const data = await res.json();
                setWhitelistJson(data);
            })
            .catch((e) => {
                console.error(e);
                setWhitelistJson(null);
            });
    }, []);

    useEffect(() => {
        let filename = "";
        if (config.DEPLOYED_CHAIN_ID == 4) {
            filename = "1_test";
        } else {
            filename = "1";
        }
        fetchJSONDataFrom(`signatures/${filename}.json`);
    }, [fetchJSONDataFrom]);

    return useMemo(() => {
        if (address && whitelistJson) {
            const key = Object.keys(whitelistJson).find(
                (key) => key.toLowerCase() == address.toLowerCase()
            );
            if (key) {
                // console.log("Found whitelist", key);
                return whitelistJson[key][0];
            } else {
                return null
            }
        } else
            return null
    }, [address, whitelistJson]);
}

export default useAllowList;