import { useMemo, useState, useEffect, useCallback } from "react";
import useWeb3Ctx from "./useWeb3Ctx";
import useInterval from "./useInterval";

import { useTheAssociationSalesContract, useTheAssociationTokenV2Contract } from "./useContract";
import { ethers } from "ethers";

export const SALE_STATUS = {
    UPCOMING_PRESALE: 0,
    PRESALE: 1,
    PRESALE_ENDED: 2,
    SALE: 3,
    SALE_ENDED: 4,
    AIRDROP: 5
};

const TOKEN_V2_MINTED = 10951

const useSaleInfo = (refreshInterval) => {
    const { address, initDone } = useWeb3Ctx()
    const saleContract = useTheAssociationSalesContract();
    const [info, setInfo] = useState({
        price: 0,
        saleStatus: SALE_STATUS.UPCOMING_PRESALE,
        timeStart: 0,
        timeEnd: 0,
        mintAvailableSale: 0,
        mintAvailableWhitelist: 0,
        maxMintWhitelist: 1,
        maxMintSale: 1,
        userMinted: 0
    });

    const fetchInfo = useCallback(() => {
        if (saleContract) {
            (async () => {
                console.log('fetching Info')
                const saleInfo = await saleContract.tellEverything(address || ethers.constants.AddressZero);
                let mintAvailableSale = 0;
                let mintAvailableWhitelist = 0;

                //console.log(saleInfo)
                const maxMintSale = Number(saleInfo.maxPerSaleMint);
                const maxMintWhitelist = Number(saleInfo.discountedPerAddress);

                if (address) {
                    const mintAvailable = await saleContract.checkDiscountAvailable(address);
                    mintAvailableSale = maxMintSale - Number(mintAvailable[2])
                    mintAvailableWhitelist = maxMintWhitelist - Number(mintAvailable[2])
                }

                //console.log(Number(saleInfo.mintPosition));
                const userMinted = Number(saleInfo.userMinted);
                const maxUserMintable = Number(saleInfo.maxTokens);

                const presaleStart = Number(saleInfo.presaleStart); //1649676600
                const presaleEnd = Number(saleInfo.presaleEnd); //1649680200
                const saleStart = Number(saleInfo.saleStart); //1649678713
                const saleEnd = Number(saleInfo.saleEnd); //1649683800
                const price = 0// ethers.utils.formatEther(saleInfo.fullPrice);

                let now = Date.parse(new Date()) / 1000;
                const presaleStarted = Math.floor(presaleStart) - now < 0;
                const presaleEnded = Math.floor(presaleEnd) - now < 0;
                const saleStarted = Math.floor(saleStart) - now < 0;
                const saleEnded = Math.floor(saleEnd) - now < 0;

                console.log(
                    "presaleStart ",
                    new Date(presaleStart * 1000).toLocaleString()
                );
                console.log("presaleEnd ", new Date(presaleEnd * 1000).toLocaleString());
                console.log("saleStart ", new Date(saleStart * 1000).toLocaleString());
                console.log("saleEnd ", new Date(saleEnd * 1000).toLocaleString());
                // console.log("fullPrice", price)

                let saleStatus = SALE_STATUS.UPCOMING_PRESALE;
                let timeStart, timeEnd;

                if (saleEnded) {
                    saleStatus = SALE_STATUS.SALE_ENDED;
                    timeStart = saleEnd// + 86400;
                } else if (saleStarted) {
                    saleStatus = SALE_STATUS.SALE;
                    timeStart = saleEnd;
                } else if (presaleEnded) {
                    saleStatus = SALE_STATUS.PRESALE_ENDED;
                    timeStart = saleStart
                } else if (presaleStarted) {
                    saleStatus = SALE_STATUS.PRESALE;
                    timeStart = presaleEnd
                } else {
                    timeStart = presaleStart;
                }

                // let timeStart, timeEnd;
                // if (!presaleStarted) {
                //     timeStart = presaleStart;
                // } else if(saleStarted) }else if (presaleEnded && !saleStarted) {
                //     timeStart = saleStart;
                // }

                // if (saleStarted) {
                //     timeEnd = saleEnd;
                // } else if (presaleStarted) {
                //     timeEnd = presaleEnd;
                // }

                setInfo({
                    price: price,
                    saleStatus: 5,
                    timeStart: timeStart,
                    timeEnd: 0,
                    mintAvailableSale: mintAvailableSale,
                    mintAvailableWhitelist: mintAvailableWhitelist,
                    maxMintSale,
                    maxMintWhitelist,
                    userMinted: 18000 + TOKEN_V2_MINTED// maxUserMintable - userMinted != 0 ? userMinted : 18000
                })
            })();
        }
    }, [saleContract, address]);

    useInterval(fetchInfo, refreshInterval || null, false)

    // useEffect(() => {
    //     fetchInfo();
    // }, [])

    useEffect(() => {
        if (initDone) {
            fetchInfo();
        }
    }, [address, initDone])

    return useMemo(() => {
        console.log(info)
        return { ...info, fetchInfo }
    }, [info]);
}

export default useSaleInfo;