import { Box, Typography } from "@mui/material";
import bg from "../../assets/images/panel-bg.png";

const sx = {
  root: {
    display: "flex",
    position: "relative",
    minHeight: "176px",
    width: "100%",
    background: `url(${bg})`,
    borderBottom: "solid 15px #000"
  },
  leftBar: {
    position: "relative",
    background: "#000",
    width: "28px",
  },
  barColor: {
    position: "absolute",
    height: "19px",
    width: "100%",
    bottom: 0,
    left: 0,
    right: 0,
    background: "#FFE500",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    paddingLeft: "10%",
  },
  number: {
    fontFamily: "action-nba",
    fontSize: "60px",
    lineHeight: "60px",
  },
  text: {
    fontFamily: "action-nba",
    fontSize: "32px",
    lineHeight: "32px",
  },
};

const colors = {
  yellow: "#FFE500",
  blue: "#143B75",
  red: "#DA1A32",
};

const InfoPanel = ({ number, text, color }) => {
  let barColor = colors.yellow;
  if (colors[color]) {
    barColor = colors[color];
  }

  return (
    <Box sx={sx.root}>
      <Box sx={sx.leftBar}>
        <Box
          sx={{
            ...sx.barColor,
            background: barColor,
          }}
        ></Box>
      </Box>
      <Box sx={sx.content}>
        <Typography sx={sx.number}>{number}</Typography>
        <Typography sx={sx.text}>{text}</Typography>
      </Box>
    </Box>
  );
};

export default InfoPanel;
