import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useEffect } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";

const sx = {
  title: {
    color: "#000000",
    fontSize: "42px",
    lineHeight: "52px",
    fontFamily: "abc-grav",
    fondWeight: "900",
    paddingLeft: "15px",
    marginRight: "20px",
    fontSize: {
      xs: "24px",
      md: "32px",
      lg: "42px",
    },
    lineHeight: {
      xs: "34px",
      md: "42px",
      lg: "52px",
    }
  },
  body: {
    fontSize: "20px",
    lineHeight: "34px",
    fontFamily: "roboto-mono",
    color: "#2D3540",
    paddingLeft: "24px",
    background: "#E0E0E0",
    display: "inline-flex",
    fontSize: {
      xs: "16px",
      md: "18px",
      lg: "20px",
    },
    lineHeight: {
      xs: "30px",
      md: "32px",
      lg: "34px",
    }
  },
  divider: {
    position: "static",
    width: "378px",
    height: "0px",
    left: "0px",
    top: "0px",
    mt: "10px",
    borderTop: "4px solid #000000",
    display: "inline-flex",
    background: "#E0E0E0",
    height: "auto",
  },
};
const CollapsibleCard = ({ title, body, index, name, link }) => {
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    var typoElemnt = document.getElementById("body" + name + index);
    typoElemnt.innerHTML = body;
    if (link) {
      var linkElement = typoElemnt.getElementsByTagName("a")[0];
      linkElement.onclick = function (event) {
        event.preventDefault();
        history.push(link);
      };
    }
  }, []);

  return (
    <Accordion
      expanded={expanded === "panel"}
      onChange={handleChange("panel")}
      sx={{
        "&:before": {
          display: "none",
        },
        "#panel-content": {
          background: "#E0E0E0",
          ">div": {
            display: "flex",
            padding: "48px 54px",
          },
        },
      }}
      style={{
        marginBottom: "14px",
        borderRadius: "0px",
        boxShadow: "#000000",
        webkitboxShadow: "0px 4px 20px -3px rgba(0,0,0,0.16)",
        boxShadow: "0px 4px 20px -3px rgba(0,0,0,0.16)",
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === "panel" ? (
            <ExpandLessIcon
              style={{
                color: "#020100",
                width: "42px",
                height: "42px",
                background: "#DA1A32",
                borderRadius: "20px",
                border: "2px solid",
              }}
            />
          ) : (
            <AddIcon
              style={{
                color: "#020100",
                width: "42px",
                height: "42px",
                background: "#FFE500",
                borderRadius: "20px",
                border: "2px solid",
              }}
            />
          )
        }
        style={{ padding: "15px" }}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography sx={sx.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ color: "#000" }}>
        <Box sx={sx.divider} />
        <Typography
          sx={sx.body}
          className="accordingDetails"
          id={"body" + name + index}
        ></Typography>
      </AccordionDetails>
    </Accordion>
  );
};

CollapsibleCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
};

export default CollapsibleCard;
