import { useMemo, useRef } from "react";
import useContainerDimensions from "../../../hooks/useContainerDimensions";

import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";

const width = "40px";
const height = "40px";

const scrollRight = keyframes`
from  { 
  transform: translateX(-100%) translateZ(0); 
}
to { 
  transform: translateX(100%) translateZ(0); 
}
`;

const scrollDown = keyframes`
from  { 
  transform: translateY(-100%) translateZ(0); 
  }
to {
  transform: translateY(100%) translateZ(0); 
  }
`;

const scrollUp = keyframes`
from  { 
  transform: translateY(100%) scale(-1) translateZ(0); 
}
to {
  transform: translateY(-100%) scale(-1) translateZ(0); 
}
`;

const durationX = 30;
const durationY = 30;

const BP1 = "@media (max-width: 500px)";
const BP2 = "@media (max-width: 767px)";
const BP3 = "@media (max-width: 920px)";
const BP4 = "@media (max-width: 1020px)";

const background = "linear-gradient(90deg, #899cba 50%, #fff 50%)";

const sx = {
  topBorderText: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    height: height,
    width: "100%",
    zIndex: 1,
    justifyContent: "start",
    background: background,
    overflow: "hidden",
    wordBreak: "break-all",
    whiteSpace: "nowrap",
  },
  leftBorderText: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    width: width,
    height: "100%",
    writingMode: "vertical-rl",
    transform: "scale(-1)",
    justifyContent: "end",
  },
  rightBorderText: {
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    width: width,
    height: "100%",
    writingMode: "vertical-rl",
    justifyContent: "start",
  },
  bottomBorderText: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    height: height,
    width: "100%",
    zIndex: 1,
    justifyContent: "start",
    background: background,
    overflow: "hidden",
    wordBreak: "break-all",
    whiteSpace: "nowrap",
  },
  scrollRight: {
    animation: `${scrollRight} ${durationX}s infinite linear`,
    backfaceVisibility: "hidden",
    perspective: 1000,
  },
  scrollUp: {
    animation: `${scrollUp} ${durationX}s infinite linear`,
    backfaceVisibility: "hidden",
    perspective: 1000,
  },
  scrollDown: {
    animation: `${scrollDown} ${durationY}s infinite linear`,
    backfaceVisibility: "hidden",
    perspective: 1000,
  },
  yDelay: {
    transform: "translateY(-100%)",
    animationDelay: `${durationY / 2}s`,
  },
  xDelay: {
    transform: "translateX(-100%)",
    animationDelay: `${durationX / 2}s`,
  },
  duplicateXTexts: {
    zIndex: 2,
    background: "unset",
  },
  borderText: {
    position: "absolute",
    lineHeight: "50px",
    fontSize: "26px",
    alignSelf: "center",
    whiteSpace: "nowrap",
    [BP1]: {
      fontSize: "18px",
    },
    [BP2]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    [BP3]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
    [BP4]: {
      fontSize: "26px",
      lineHeight: "40px",
    },
  },
  bulletPoint: {
    display: "inline-block",
    height: "16px",
    width: "16px",
    background: "#000",
    borderRadius: "100px",
    [BP1]: {
      height: "12px",
      width: "12px",
    },
    [BP2]: {
      height: "14px",
      width: "14px",
    },
  },
  spacing: {
    minWidth: "10px",
  },
  textRef: {
    position: "absolute",
    opacity: "0",
    visibility: "hidden"
  },
};

const borderTextItem = (
  <>
    <Box component="span" sx={sx.spacing}>
      {" "}
    </Box>
    <Box component="span" sx={sx.bulletPoint}></Box>
    <Box component="span" sx={sx.spacing}>
      {" "}
    </Box>
    2022 NBA PLAYOFFS
    <Box component="span" sx={sx.spacing}>
      {" "}
    </Box>
  </>
);

const HeroBorderTexts = () => {
  const widthRef = useRef();
  const heightRef = useRef();
  const textWidthRef = useRef();
  const textHeightRef = useRef();

  const { width } = useContainerDimensions(widthRef);
  const { height } = useContainerDimensions(heightRef);
  const { width: textWidth } = useContainerDimensions(textWidthRef);
  const { height: textHeight } = useContainerDimensions(textHeightRef);

  const xBorderTexts = useMemo(() => {
    const texts = [];
    if (width && textWidth) {
      if (textWidth < width) {
        for (var i = 0; i * textWidth < width; i++) {
          texts.push(borderTextItem);
        }
      }
    }
    return texts;
  }, [width, textWidth]);

  const yBorderTexts = useMemo(() => {
    const texts = [];

    if (height && textHeight) {
      if (textHeight < height) {
        for (var i = 0; i * textHeight < height; i++) {
          texts.push(borderTextItem);
        }
      }
    }
    return texts;
  }, [height, textHeight]);

  return (
    <>
      <Box sx={sx.topBorderText} ref={widthRef}>
        <Typography
          variant="heading1"
          component="div"
          sx={{ ...sx.borderText, ...sx.scrollRight }}
        >
          {/* Measure text width */}
          <Box ref={textWidthRef} sx={sx.textRef}>
            {borderTextItem}
          </Box>
          {xBorderTexts}
        </Typography>
      </Box>

      <Box sx={sx.bottomBorderText}>
        <Typography
          variant="heading1"
          component="div"
          sx={{ ...sx.borderText, ...sx.scrollRight }}
        >
          {xBorderTexts}
        </Typography>
      </Box>

      <Box sx={sx.leftBorderText} ref={heightRef}>
        <Typography
          variant="heading1"
          component="div"
          sx={{ ...sx.borderText, ...sx.scrollDown, textAlign: "end" }}
        >
          {/* Measure text height */}
          <Box ref={textHeightRef} sx={sx.textRef}>
            {borderTextItem}
          </Box>
          {yBorderTexts}
        </Typography>
      </Box>
      <Box sx={sx.rightBorderText}>
        <Typography
          variant="heading1"
          component="div"
          sx={{ ...sx.borderText, ...sx.scrollDown }}
        >
          {yBorderTexts}
        </Typography>
      </Box>
      {/* Duplicate texts for scrolling loop */}
      <Box sx={{ ...sx.topBorderText, ...sx.duplicateXTexts }}>
        <Typography
          variant="heading1"
          component="div"
          sx={{ ...sx.borderText, ...sx.scrollRight, ...sx.xDelay }}
        >
          {xBorderTexts}
        </Typography>
      </Box>

      <Box sx={{ ...sx.bottomBorderText, ...sx.duplicateXTexts }}>
        <Typography
          variant="heading1"
          component="div"
          sx={{ ...sx.borderText, ...sx.scrollRight, ...sx.xDelay }}
        >
          {xBorderTexts}
        </Typography>
      </Box>
      <Box sx={sx.leftBorderText}>
        <Typography
          variant="heading1"
          component="div"
          sx={{
            ...sx.borderText,
            textAlign: "end",
            ...sx.scrollDown,
            ...sx.yDelay,
          }}
        >
          {yBorderTexts}
        </Typography>
      </Box>
      <Box sx={sx.rightBorderText}>
        <Typography
          variant="heading1"
          component="div"
          sx={{ ...sx.borderText, ...sx.scrollDown, ...sx.yDelay }}
        >
          {yBorderTexts}
        </Typography>
      </Box>
    </>
  );
};

export default HeroBorderTexts;
