import Web3Ctx from "../context/Web3Ctx";
import { useMemo, useContext } from 'react'
import { getContract } from '../utils'

import { abi as ZOOM_ABI } from '../abi/Zoom.json'
import { abi as THE_ASSOCIATION_TOKEN_ABI } from '../abi/TheAssociationToken.json'
import { abi as THE_ASSOCIATION_SALES_ABI } from '../abi/TheAssociationSales.json'
import { abi as THE_ASSOCIATION_TOKENV2_ABI } from "../abi/TheAssociationTokenV2.json"
import {
    ZOOM_ADDRESSES,
    THE_ASSOCIATION_TOKEN_ADDRESSES,
    THE_ASSOCIATION_SALES_ADDRESSES,
    THE_ASSOCIATION_TOKENV2_ADDRESSES
} from '../abi/constants/addresses'

// returns null on errors
export function useContract(
    addressOrAddressMap,
    ABI,
    withSignerIfPossible = true) {
    const { defaultProvider: deployedLibrary, ethersProvider: library, address: account, chainId, isCorrectNetwork, defaultChainId } = useContext(Web3Ctx);

    return useMemo(() => {
        if (!isCorrectNetwork && (!deployedLibrary || !defaultChainId)) return null
        if (isCorrectNetwork && (!addressOrAddressMap || !ABI || !library || !chainId)) return null
        let address
        if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
        else address = addressOrAddressMap[isCorrectNetwork ? chainId : defaultChainId]
        if (!address) return null
        let provider
        if (isCorrectNetwork) provider = library
        else provider = deployedLibrary
        try {
            return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account, deployedLibrary, isCorrectNetwork])
}

export function useZoomContract() {
    return useContract(ZOOM_ADDRESSES, ZOOM_ABI, false)
}

export function useTheAssociationTokenContract() {
    return useContract(THE_ASSOCIATION_TOKEN_ADDRESSES, THE_ASSOCIATION_TOKEN_ABI, true)
}

export function useTheAssociationSalesContract() {
    return useContract(THE_ASSOCIATION_SALES_ADDRESSES, THE_ASSOCIATION_SALES_ABI, true)
}

export function useTheAssociationTokenV2Contract() {
    return useContract(THE_ASSOCIATION_TOKENV2_ADDRESSES, THE_ASSOCIATION_TOKENV2_ABI, true)
}