const countryDenyList = {
  "IR": "Iran",
  "SY": "Syria",
  "KP": "North Korea",
  "RU": "Russia",
  "CN": "China",
  "TW": "Taiwan",
  "BY": "Belarus",
  "HK": "Hong Kong",
  "MO": "Macau",
}

export default countryDenyList;