import { createTheme } from '@mui/material';

const COLORS = {
  dark: '#000',
  white: '#FFF',
  error: '#FF1414',
};

const BP1 = '@media (max-width: 539px)';
const BP2 = '@media (max-width: 999px)';
const BP3 = '@media (max-width: 1480px) and (min-width:1199px)';

const theme = createTheme({
  typography: {
    fontFamily: 'roboto-mono',
    fontSize: '16px',
    allVariants: {
      color: COLORS.white,
    },
    heading1: {
      fontFamily: 'abc-grav',
      fontWeight: '900',
      fontSize: '32px',
      lineHeight: '42px',
      transition: 'all .3s',
      [BP1]: {
        fontSize: '24px',
      },
    },
    heading2: {
      fontFamily: 'poppins',
      fontWeight: '900',
      fontSize: '26px',
      lineHeight: '34px',
    },
    title: {
      fontFamily: 'Overpass',
      fontWeight: '900',
      fontSize: '3rem',
      lineHeight: '2.4rem',
    },
    body1: {
      fontFamily: 'roboto-mono',
      fontSize: '16px',
      lineHeight: '29px',
      color: '#000003',
    },
    h6: {
      fontSize: '18px',
      fontWeight: '800',
    },
  },
  palette: {
    primary: {
      main: COLORS.white,
    },
    text: {
      primary: COLORS.white,
    },
    blue: {
      main: '#5deffb',
    },
    error: {
      main: COLORS.error,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          heading1: 'p',
          heading2: 'p',
          text: 'p',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '16px',
          padding: '16px',
          backgroundColor: '#9A989A',
          color: '#000207',
          boxShadow: '0px 0px 30px #FFFFFF33',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'white',
          backgroundColor: 'black',
          borderRadius: '0',
          '&.Mui-disabled': {
            backgroundColor: '#BE1FDA',
            color: '#FFF',
            opacity: 0.4,
          },
        },
        contained2: {
          fontFamily: 'abc-grav',
          color: '#000',
          backgroundColor: '#FFE500',
          borderRadius: '0',
          borderColor: '#000',
          borderWidth: '3px',
          borderStyle: 'solid',
          '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.5,
          },
          '&:hover': {
            backgroundColor: '#FFE500',
          },
        },
        contained3: {
          fontFamily: 'abc-grav',
          color: '#000',
          backgroundColor: '#000',
          borderRadius: '0',
          borderColor: '#FFE500',
          borderWidth: '3px',
          borderStyle: 'solid',
          color: '#fff',
          fontSize: '15px',
          height: '48px',
          '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.5,
          },
          '&:hover': {
            backgroundColor: '#FFE500',
            color: '#000',
            borderColor: '#000',
          },
        },
        outlined: {
          minHeight: '40px',
          fontSize: '0.8rem',
          fontWeight: '500',
          borderRadius: '8px',
          borderWidth: '2px',
          '&:hover': {
            border: '2px solid #FFF;',
          },
        },
        text: {
          '&.Mui-disabled': {
            color: '#BE1FDA',
            opacity: 0.4,
          },
        },
        root: {
          '&:focus': {
            outline: 'none',
          },
          '&:hover': {
            opacity: 1,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // minWidth: "100px",
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },
  },
});

export default theme;
