import { Box, Input, Typography, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import open from '../../../../assets/images/open.png';
import close from '../../../../assets/images/close.png';
import Checkbox from '@mui/material/Checkbox';
import '../../../../App.css';

const sx = {
  title: {
    color: '#030000',
    fontSize: '20px',
    lineHeight: '24px',
    fontFamily: 'abc-grav',
    padding: '0px 10.5px',
  },

  body: {
    fontSize: '16px',
    fontFamily: 'roboto-mono',
    color: '#030000',
    scrollbarColor: '#FFE500 #696969 ',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      background: '#696969',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FFE500',
    },
  },
  textBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: '18px',
  },
  filterName: {
    fontSize: '14px',
    lineHeight: '19px',
    mt: '6px',
  },
  value: { fontSize: '12px' },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  manTitle: {
    width: '100%',
  },
  details: {
    color: '#000',
  },
  searchInput: {
    color: '#000000',
    // position: 'fixed',
    width: '247px',
    mb: '20px',
  },
};
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const FilterItem = ({
  filterTitle,
  filters,
  index,
  handleCheckBoxChanges,
  selectedFilters,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [filterList, setfilterList] = useState(filters);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const checked = (filterTitle, filterKey) => {};

  const handleSearch = (e) => {
    let searchText = e.target.value.toLowerCase();
    let tmpObject;
    if (searchText.length > 2) {
      Object.keys(filters).map((filterKey, index) => {
        if (filterKey.toLowerCase().indexOf(searchText) >= 0) {
          tmpObject = { ...tmpObject, [filterKey]: filters[filterKey] };
        } else return;
      });
      setfilterList(tmpObject);
    } else {
      setfilterList(filters);
    }
  };

  return (
    // {index === 0 && <Typography sx={sx.title}>{filterTitle}</Typography>}
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      sx={{
        '&:before': {
          display: 'none',
        },
      }}
      style={{
        marginBottom: '15px',
        borderRadius: 'unset',
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded === 'panel1' ? (
            <img src={open} alt="open-button" />
          ) : (
            <img src={close} alt="close-button" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={sx.title}>{filterTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.details}>
        <Input
          sx={sx.searchInput}
          onChange={(e) => handleSearch(e)}
          placeholder="Search"
        />
        <Typography
          sx={sx.body}
          className="accordingDetails"
          style={{
            maxHeight: '329px',
            overflowY: 'auto',
            paddingRight: '10px',
          }}
        >
          {filterList &&
            Object.keys(filterList).map((filterKey, index) => {
              return (
                <>
                  <Box sx={sx.textBox} key={index}>
                    <Box sx={sx.filters}>
                      <Checkbox
                        id={filterTitle + index}
                        className="check-box"
                        onChange={() => {
                          handleCheckBoxChanges(filterTitle, index, filterKey);
                        }}
                        checked={selectedFilters.some((item) => {
                          for (const [key, value] of Object.entries(item)) {
                            if (key == filterTitle && value == filterKey)
                              return true;
                            else return false;
                          }
                        })}
                        sx={{
                          color: '#000',
                          '&.Mui-checked': {
                            color: '#FFE500',
                          },
                          '& .MuiSvgIcon-root': { fontSize: 28 },
                        }}
                      />
                      <Typography sx={sx.filterName}>{filterKey}</Typography>{' '}
                    </Box>

                    <Typography sx={sx.value}>
                      {'('}
                      {filters[filterKey]
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      {')'}
                    </Typography>
                  </Box>
                </>
              );
            })}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterItem;
