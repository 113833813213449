import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import clear from '../../../../assets/images/clear.svg';
const BP1 = '@media (max-width: 780px)';

const sx = {
  box: {
    border: '1px solid #0000',
    color: '#FFFF',
    [BP1]: {
      width: '100%',
    },
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '14px 16px',
    gap: '20px',
  },
};

const FilterDataDiplayer = ({ elem, clearOne }) => {
  return (
    <Box sx={sx.box}>
      {Object.values(elem).map((title) => (
        <>
          <Box sx={sx.filterBox}>
            <Typography>{title}</Typography>
            <img
              src={clear}
              alt="clear-button"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                clearOne(elem);
              }}
            />
          </Box>
        </>
      ))}
    </Box>
  );
};

export default FilterDataDiplayer;
