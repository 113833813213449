import { Typography, Button } from '@mui/material';
import { Box, width } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Header from '../Home/components/Header';
import CardHolder from './components/Card/CardHolder';
// import data from './components/Card/metadata.json';
import Filter from './components/Filter/Filter.jsx';
import { filterData } from './filter.js';
import MobileFilter from './components/Filter/MobileFilter';
import FilterDataDiplayer from './components/Filter/FilterDataDiplayer';
import axios from 'axios';
import config from '../../config';
import { SpinnerCircular } from 'spinners-react';

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 1024px)';
const BP3 = '@media (max-width: 420px)';
const BP4 = '@media (max-width: 590px)';
const BP5 = '@media (max-width: 500px)';
const BP6 = '@media (max-width: 386px)';

const sx = {
  whiteBackground: {
    position: 'absolute',
    backgroundColor: '#000',
    zIndex: '-1',
    width: '100%',
    height: '100%',
  },
  root: {
    width: 'calc(100% - 64px)',
    height: 'calc(100% - 80px)',
    background: '#102F5E',
    webkitBackgroundSize: '30px 30px',
    backgroundSize: '30px 30px',
    backgroundImage:
      'linear-gradient(to right, black 1px, transparent 1px), linear-gradient(to bottom, black 1px, transparent 1px)',
    overflowY: 'auto',
    m: '94px 32px  40px 32px',
    py: '64px',
    px: '24px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '50px',
    [BP4]: {
      flexDirection: 'column',
    },
  },
  header: {
    fontSize: '66px',
    lineHeight: '80px',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    mb: '50px',
    [BP2]: {
      fontSize: '54px',
      lineHeight: '66px',
    },
    [BP3]: {
      fontSize: '44px',
      lineHeight: '56px',
    },
    [BP5]: {
      fontSize: '40px',
      lineHeight: '44px',
    },
    [BP6]: {
      fontSize: '35px',
      lineHeight: '44px',
    },
  },
  explorerHolder: {
    maxWidth: '1000px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    flexWrap: 'wrap',
    mb: '24px',
  },
  filterCount: {
    maxWidth: '30px',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#FFE500',
    borderRadius: '50%',
    ml: '10px',
  },
  text: {
    fontSize: '13px',
    lineHeight: '12px',
    color: '#000',
    fontFamily: 'abc-grav',
  },
  btn: {
    '&:hover': {
      div: {
        backgroundColor: '#000',
        color: '#fff',
      },
    },
  },
  spinnerHolder: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  count: {
    fontSize: '16px',
    color: '#fff',
    mt: '10px',
    mb: '19px',
  },
};

const Explorer = () => {
  const [filters, setFilters] = useState({});
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [selectedFilters, setselectedFilters] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [currentPage, _setCurrentPage] = useState(1);
  const [mobileView, setMobileView] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterCounter, setFilterCounter] = useState(0);
  const [clearCardsArray, setClearCardsArray] = useState(0);
  const [nftsCount, setNftsCount] = useState(0);

  useEffect(() => {
    // setDataLoaded(true)
    axios
      .post(
        `${config.FILTER_API_URL}/metadata/filter?page=${currentPage}`,
        activeFilters
      )
      .then((response) => {
        setNfts(response.data.metadata);
        setNftsCount(response.data.count);
        // setDataLoaded(false)
      })
      .catch((error) => {
        console.error(error);
      });
    // console.log(nfts)
  }, [currentPage, selectedFilters]);

  useEffect(() => {
    console.log(currentPage, ' currentpage eeeeeeeeeeeeeeeeeeeeeeeee');
  }, [currentPage]);

  useEffect(() => {
    if (window.innerWidth < 600) {
      setMobileView(true);
    }
    axios
      .get(`${config.FILTER_API_URL}/filters`)
      .then((response) => {
        setFilters(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // console.log(currentPage, " currentpage")
  const handleCheckBoxChanges = (section, id, value) => {
    const checkbox = document.getElementById(`${section + id}`);
    let tmpArray = [];
    let tmpObject = {};
    let selectedArray = [];
    if (checkbox.checked) {
      setClearCardsArray(clearCardsArray + 1);
      if (section in activeFilters) {
        tmpObject = activeFilters;
        tmpArray = tmpObject[section];
        tmpArray.push(value);
        tmpObject[section] = tmpArray;
        setActiveFilters(tmpObject);
        Object.keys(activeFilters).map((key) => {
          Object.values(activeFilters[key]).map((filterKey, index) => {
            setselectedFilters([...selectedFilters, { [section]: filterKey }]);
          });
          _setCurrentPage(1);
        });
      } else {
        tmpObject = activeFilters;
        tmpArray.push(value);
        tmpObject[section] = tmpArray;
        setActiveFilters(tmpObject);
        Object.keys(activeFilters).map((key) => {
          Object.values(activeFilters[key]).map((filterKey, index) => {
            setselectedFilters([...selectedFilters, { [section]: filterKey }]);
          });
        });
        _setCurrentPage(1);
      }
    } else if (!checkbox.checked) {
      setClearCardsArray(clearCardsArray + 1);
      tmpObject = activeFilters;
      tmpArray = tmpObject[section];
      const filteredArray = tmpArray.filter((data) => data !== value);
      tmpObject[section] = filteredArray;
      if (tmpObject[section].length === 0) {
        delete tmpObject[section];
      }
      setActiveFilters(tmpObject);
      Object.keys(tmpObject).map((key) => {
        Object.values(tmpObject[key]).map((filterKey, index) => {
          selectedArray.push({ [section]: filterKey });
        });
      });
      setselectedFilters(selectedArray);
      _setCurrentPage(1);
    }
    // console.log(tmpObject, 'tpmObject');
    // console.log(activeFilters, 'active filters, ez megy majd BE');
  };

  const clearAll = () => {
    setActiveFilters({});
    setselectedFilters([]);
    _setCurrentPage(1);
    setClearCardsArray(clearCardsArray + 1);
  };

  const clearOne = (elem) => {
    setClearCardsArray(clearCardsArray + 1);
    _setCurrentPage(1);
    let tmpObj = {};
    let tmpArray = [];
    for (const [key, value] of Object.entries(elem)) {
      tmpObj = activeFilters;
      tmpArray = tmpObj[key];
      const filteredArray = tmpArray.filter((data) => data !== value);
      tmpObj[key] = filteredArray;
      if (tmpObj[key].length === 0) {
        delete tmpObj[key];
      }
      setActiveFilters(tmpObj);
      const selectedFiltersArray = selectedFilters.filter(
        (data) => data[key] !== value
      );
      setselectedFilters(selectedFiltersArray);
    }
  };
  return (
    <>
      <Box sx={sx.whiteBackground}></Box>
      <Header />
      <Box sx={sx.root} id="scrollable-target">
        <Typography variant="heading1" sx={sx.header}>
          explore the collection
        </Typography>

        <Box sx={sx.container}>
          {mobileView ? (
            <Button variant="contained3" onClick={() => setOpenFilter(true)}>
              Filters
              {selectedFilters.length == 0 ? (
                <Box></Box>
              ) : (
                <Box sx={sx.filterCount}>
                  <Typography sx={sx.text}>{filterCounter}</Typography>
                </Box>
              )}
            </Button>
          ) : (
            <Filter
              filterData={filters}
              setActiveFilters={setActiveFilters}
              handleCheckBoxChanges={handleCheckBoxChanges}
              selectedFilters={selectedFilters}
            />
          )}
          {isDataLoaded ? (
            <Box sx={sx.spinnerHolder}>
              <SpinnerCircular style={{ width: '150px' }} />
            </Box>
          ) : (
            <Box sx={sx.explorerHolder}>
              <Typography sx={sx.count}>
                {' '}
                <span style={{ fontWeight: '700' }}>
                  {nftsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </span>{' '}
                NFTs
              </Typography>
              {selectedFilters.length > 0 && (
                <Box id="render-selected-datas" sx={sx.filterContainer}>
                  {!mobileView &&
                    selectedFilters.map((elem, index) => {
                      return (
                        <FilterDataDiplayer
                          elem={elem}
                          key={index}
                          clearOne={clearOne}
                        />
                      );
                    })}
                  {!mobileView && (
                    <Box
                      style={{
                        width: '233px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        onClick={() => {
                          clearAll();
                        }}
                        style={{ fontFamily: 'abc-grav', fontSize: '18px' }}
                      >
                        Clear All
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              <CardHolder
                nfts={nfts}
                currentPage={currentPage}
                _setCurrentPage={_setCurrentPage}
                clearCardsArray={clearCardsArray}
              />
            </Box>
          )}
        </Box>
      </Box>
      <MobileFilter
        openFilter={openFilter}
        setOpen={setOpenFilter}
        filterData={filters}
        setActiveFilters={setActiveFilters}
        handleCheckBoxChanges={handleCheckBoxChanges}
        selectedFilters={selectedFilters}
        clearOne={clearOne}
        clearAll={clearAll}
        setFilterCounter={setFilterCounter}
      />
    </>
  );
};

export default Explorer;
