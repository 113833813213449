import { SupportedChainId } from './chain'

export const ZOOM_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x7cdF091AF6a9ED75E3192500d3e5BB0f63e22Dea",
    [SupportedChainId.RINKEBY]: "0x491592F30D9a3d1887F486eA2A3c72ad82fAcF4D"
}

export const THE_ASSOCIATION_TOKEN_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0x9b8b9c7c02be0bd0aa4d669bf6a1f6003424c6dc",
    [SupportedChainId.RINKEBY]: "0x99af1a36527a24889475309c7e66b8058aa03ba6"
}

export const THE_ASSOCIATION_SALES_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xdd5a649fc076886dfd4b9ad6acfc9b5eb882e83c",
    [SupportedChainId.RINKEBY]: "0xfd64562b1f77b1f0dfa7dd409af667f98ee1faf6"
}

export const THE_ASSOCIATION_TOKENV2_ADDRESSES = {
    [SupportedChainId.MAINNET]: "0xD7bEA2b69C7a1015aAdAA134e564eEe6d34149C0",
    [SupportedChainId.RINKEBY]: "0x4633c6e57e3685079747de26d22a60c631c89435"
}