import { Grid, Box, Typography } from "@mui/material";
import icon from "../../assets/images/basketball-icon.svg";

const BP1 = "@media (max-width: 899px)";

const sx = {
  root: {
    position: "relative",
    minHeight: "250px",
    height: "250px",
    width: "100%",
    my: 3,
    border: "5px solid #000",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    [BP1]: {
      height: "auto",
    },
  },
  backgroundOverlayLeft: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    width: "auto",
    height: "100%",
    [BP1]: {
      height: "auto",
    },
  },
  backgroundOverlayRight: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    height: "100%",
    [BP1]: {
      backgroundColor: "unset",
    },
  },
  dateContainer: {
    display: "flex",
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundSize: "20px 20px",
    backgroundImage: `
        linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)`,
    alignItems: "center",
    justifyContent: "center",
    [BP1]: {
      height: "50%",
      flexDirection: "row",
    },
  },
  month: {
    fontFamily: "action-nba",
    writingMode: "vertical-rl",
    transform: "scale(-1)",
    textAlign: "center",
    fontSize: "60px",
    lineHeight: "60px",
    [BP1]: {
      writingMode: "unset",
      transform: "none",
      fontSize: "80px",
      lineHeight: "80px",
    },
  },
  day: {
    fontFamily: "action-nba",
    color: "#DA1A32",
    textAlign: "center",
    fontSize: "150px",
    lineHeight: "150px",
    [BP1]: {
      fontSize: "80px",
      lineHeight: "80px",
      padding: "25px 15px",
    },
  },
  contentContainer: {
    height: "100%",
    p: 3,
    padding: "24px",
    [BP1]: {
      display: "flex",
      flexDirection: "column",
      padding: "24px 0",
    },
  },
  leftContentBox: {
    position: "relative",
    background: "#C4C4C4",
    border: "5px solid #000",
    width: "190px",
    height: "190px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mr: 3,
    [BP1]: {
      margin: "auto",
    },
  },
  textContainer: {
    display: "flex",
    alignSelf: "flex-start",
    alignItems: "center",
    background: "#000",
    pr: 2,
    mt: 1,
    [BP1]: {
      width: "100%",
    },
  },
  text: {
    color: "#fff",
    fontSize: "24px",
    [BP1]: {
      fontSize: "20px",
    },
  },
  textIcon: {
    maxHeight: "20px",
    height: "100%",
    m: 1,
  },
  dottedBorder: {
    position: "absolute",
    right: -6,
    width: 0,
    height: "100%",
    borderRight: "11px dotted #000",
    [BP1]: {
      width: "100%",
      height: 0,
      right: 0,
      bottom: -6,
      borderRight: "none",
      borderBottom: "11px dotted #000",
    },
  },
};

const CalendarText = ({ text }) => {
  return (
    <Box sx={sx.textContainer}>
      <Box component="img" sx={sx.textIcon} src={icon}></Box>
      <Typography sx={sx.text}>{text}</Typography>
    </Box>
  );
};

const CalendarItem = ({ day, month, texts, background }) => {
  return (
    <Box sx={sx.root} style={{ backgroundImage: `url(${background})` }}>
      <Grid container height="100%">
        <Grid item xs={12} md={3}>
          <Box sx={sx.backgroundOverlayLeft}>
            <Grid sx={sx.dateContainer}>
              <Typography sx={sx.month}>{month}</Typography>
              <Typography sx={sx.day}>{day}</Typography>
              <Box sx={sx.dottedBorder}></Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box sx={sx.backgroundOverlayRight}>
            <Grid container sx={sx.contentContainer}>
              {/* <Grid item xs={"auto"}>
                <Box sx={sx.leftContentBox}>
                  <Typography variant="heading1" fontSize={56}>
                    FPO
                  </Typography>
                </Box>
              </Grid> */}
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                  marginLeft: "40px",
                }}
              >
                {texts.map((text, index) => {
                  return <CalendarText key={"_" + index} text={text} />;
                })}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalendarItem;
