import { useRef } from "react";
import closeIcon from "../../../assets/images/close.svg";
import closeIconBlack from "../../../assets/images/close-black.svg";
import backIcon from "../../../assets/images/back-icon.svg";
import backIconBlack from "../../../assets/images/back-icon-black.svg";

import { Box, Typography, Button } from "@mui/material";

const polygon = (cornerSize) => {
  return `polygon(
      0 ${cornerSize},     /* top left */
      ${cornerSize} 0,     /* top left */
      100% 0,    /* top right */
      100% 0,   /* top right */
      100% calc(100% -  ${cornerSize}),  /* bottom right */
      calc(100% -  ${cornerSize}) 100%,  /* bottom right */
      0 100%,   /* bottom left */
      0 100%      /* bottom left */
      )`;
};
const BP2 = "@media (max-width: 767px)";
const BP3 = "@media (max-width: 900px)";

const sx = {
  popupContainer: {
    position: "absolute",
    backgroundColor: "#000",
    width: "73%", // HACK: Hardcoded value
    height: "auto",
    minHeight: "70%",
    left: 0,
    bottom: 0,
    zIndex: 10,
    clipPath: polygon("120px"),
    transformOrigin: "0% 100%",
    transform: "scale(1)",
    transition: "all 0.4s ease-in-out",
    p: "100px",
    pl: "120px",
    [BP2]: {
      width: "95%",
    },
    [BP3]: {
      minHeight: "unset"
    }
  },
  backIcon: {
    height: "40%",
    pr: 1,
  },
  header: {
    fontSize: "48px",
    lineHeight: "48px",
    mb: 2,
    color: "#fff",
    [BP2]: {
      fontSize: "16px",
    },
    whiteSpace: "break-spaces",
  },
  description: {
    fontSize: "28px",
    lineHeight: "32px",
    color: "#fff",
    [BP2]: {
      fontSize: "16px",
    },
    whiteSpace: "break-spaces",
  },
  closeIcon: {
    position: "absolute",
    width: "40px",
    height: "40px",
    right: "3.5%",
    top: "5%",
    "&:hover": {
      opacity: "0.8",
      cursor: "pointer",
    },
  },
  popupBtn: {
    fontSize: "18px",
    minWidth: "160px",
    ml: 0,
    mr: 5,
    [BP2]: {
      fontSize: "10px",
      minWidth: "120px",
    },
  },
  popupBackBtn: {
    background: "#fff",
  },
  popupBackBtn2: {
    background: "#000",
    color: "#fff",
    "&:hover": {
      color: "#000",
    },
  },
};

export const STYLE = {
  RED: {
    backgroundColor: "#DA1A32",
    closeIc: closeIcon,
    header: sx.header,
    description: sx.description,
    backBtn: sx.popupBackBtn,
    backIc: backIconBlack,
  },
  BLUE: {
    backgroundColor: "#1D428A",
    closeIc: closeIcon,
    header: sx.header,
    description: sx.description,
    backBtn: sx.popupBackBtn,
    backIc: backIconBlack,
  },
  YELLOW: {
    backgroundColor: "#FFE500",
    closeIc: closeIconBlack,
    header: { ...sx.header, color: "#000" },
    description: { ...sx.description, color: "#000" },
    backBtn: sx.popupBackBtn2,
    backIc: backIcon,
    backIc2: backIconBlack,
  },
  DEFAULT: {
    backgroundColor: "#000",
    closeIc: closeIcon,
    header: sx.header,
    description: sx.description,
    backBtn: sx.popupBackBtn,
    backIc: backIconBlack,
  },
};

const HeroPopup = ({
  showPopup,
  closePopup,
  text,
  text2,
  popupStyle,
  btnText,
  btnOnClick,
}) => {
  const imgRef = useRef();
  const style = popupStyle || STYLE.DEFAULT;
  return (
    <Box
      sx={{
        ...sx.popupContainer,
        ...(showPopup ? { transform: "scale(1)" } : { transform: "scale(0)" }),
        backgroundColor: style.backgroundColor,
      }}
    >
      <Box
        component="img"
        src={style.closeIc}
        sx={sx.closeIcon}
        onClick={() => closePopup()}
      ></Box>
      <Typography variant="heading1" sx={style.header}>
        {text}
      </Typography>
      <Typography variant="heading1" sx={style.description}>
        {text2}
      </Typography>
      <Box display="flex" mt={5}>
        {showPopup && (
          <Button
            sx={{ ...sx.popupBtn, ...style.backBtn }}
            variant="contained2"
            onClick={() => closePopup()}
            onMouseOver={(e) => {
              if (imgRef.current) {
                imgRef.current.src = style.backIc2 || style.backIc;
              }
            }}
            onMouseOut={(e) => {
              if (imgRef.current) imgRef.current.src = style.backIc;
            }}
          >
            <Box
              ref={imgRef}
              sx={sx.backIcon}
              component="img"
              src={style.backIc}
            ></Box>{" "}
            GO BACK
          </Button>
        )}
        {btnText && (
          <Button
            sx={sx.popupBtn}
            variant="contained2"
            onClick={() => {
              if (btnOnClick) {
                btnOnClick();
              }
            }}
          >
            {btnText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default HeroPopup;
