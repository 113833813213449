import React from "react";

import { Box } from "@mui/material";

import close from "../../../assets/images/checkout-close.svg";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 719px)";
const BP3 = "@media (max-width: 600px)";

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    my: "auto",
    py: "44px",
    px: "125px",
    transition: "all .3s",
    [BP1]: {
      px: "85px",
    },
    [BP2]: {
      px: "25px",
    },
  },
  container: {
    minHeight: "150px",
    position: "relative",
    margin: "auto",
    width: "100%",
    maxWidth: "600px",
    borderRadius: "16px",
    border: "2px solid #050000",
    overflow: "hidden",
    transition: "all .3s",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    px: "30px",
    pt: "30px",
    textAlign: "center",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  closeBtn: {
    position: "absolute",
    width: "14px",
    height: "14px",
    top: "20px",
    bottom: "0",
    right: "20px",
    cursor: "pointer",
    pointerEvents: "auto",
    transition: "all .3s",
    "&:hover": {
      opacity: 0.8,
    },
    zIndex: 2,
  },
  header: {
    fontFamily: "poppins-semibold",
    position: "relative",
    width: "100%",
    minHeight: "50px",
    color: "#FF692B",
    mb: 2,
  },
  body: {
    width: "100%",
    minHeight: "50px",
    fontFamily: "bau",
    fontSize: "16px",
  },
  footer: {
    width: "100%",
    minHeight: "10px",
  },
};

const CheckoutCard = ({
  header,
  footer,
  children,
  setOpen,
  isOpen = true,
  isTxPopup,
}) => {
  return (
    <Box
      sx={{
        ...sx.root,
        ...(isOpen ? { display: "flex" } : { display: "none" }),
      }}
    >
      <Box sx={sx.container}>
        <Box sx={sx.header}>
          <Box component="h1" sx={sx.header}>
            {header}
          </Box>
        </Box>
        <Box sx={sx.body}>{children}</Box>
        <Box sx={sx.footer}>{footer}</Box>

        {!isTxPopup && (
          <Box
            sx={sx.closeBtn}
            onClick={() => {
              if (setOpen) {
                setOpen(false);
              }
            }}
          >
            <img src={close} style={{ width: "100%" }} alt="Close" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckoutCard;
