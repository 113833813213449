import { Grid, Typography, Box } from "@mui/material";
import associationBanner from "../../assets/images/association-header.svg";

const BP1 = "@media (max-width: 899px)";

const sx = {
  root: {
    display: "flex",
    background: "#000",
    height: "100vh",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image:{
    height: '75px',
    width: 'auto',
    marginBottom: "40px",
    [BP1]: {
      height: '45px',
    }
  },
  text: {
    color: "#FFF",
    fontSize: "30px",
    lineHeight: "58px",
    fontFamily: 'abc-grav',
    [BP1]: {
      fontSize: "16px",
      lineHeight: "28px",
    }
  }
}

const RegionLocked = () => {
  return (
    <Grid sx={sx.root}>
       <Grid>
        <Box
          component="img"
          sx={sx.image}
          src={associationBanner}
        ></Box>
      </Grid>
      <Grid>
        <Typography sx={sx.text}>Your region is not supported.</Typography>
      </Grid>
      <Grid>
        <Typography sx={sx.text}>The Association NFT is not available in your location.</Typography>
      </Grid>
    </Grid>
  )
};

export default RegionLocked;
