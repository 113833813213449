import { useState } from "react";

import playerTraits from "../constant/playerTraits.json";
import teamTraits from "../constant/teamTraits.json";
import icon from "../../../assets/images/basketball-icon-blue.svg";

import PaperBox from "../../common/PaperBox";
import bg from "../../../assets/images/static-bg.png";
import { Box, Grid, Typography, ButtonGroup, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 1024px)";
const BP3 = "@media (max-width: 420px)";

const sx = {
  root: {
    position: "relative",
    background: "#fff",
    padding: "2%",
  },
  container: {
    position: "relative",
    padding: "2%",
    height: "100%",
    width: "100%",
    background: "#BF172B",
    backgroundSize: "30px 30px",
    backgroundImage: `
        linear-gradient(to right, black 1px, transparent 1px),
        linear-gradient(to bottom, black 1px, transparent 1px)`,
    border: "1px solid black",
  },
  header: {
    fontSize: "66px",
    lineHeight: "80px",
    color: "#fff",
    [BP2]: {
      fontSize: "54px",
      lineHeight: "66px",
    },
    [BP3]: {
      fontSize: "44px",
      lineHeight: "56px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #fff",
    borderRadius: "8px",
    background: `url(${bg})`,
    backgroundColor: "#102F5E",
    backgroundBlendMode: "multiply",
    height: "100%",
    mx: 2,
    my: 5,
    p: 3,
  },
  contentHeader: {
    fontFamily: "action-nba",
    fontSize: "70px",
    background: "#fff",
    borderRadius: "20px",
    textAlign: "center",
    alignSelf: "center",
    py: 3,
    px: 2,
    my: 5,
    [BP1]: {
      fontSize: "54px",
    },
  },
  tabContainer: {
    width: "100%",
    paddingTop: "30px",
  },
  tabBtns: {
    fontSize: "24px",
    width: "100%",
    backgroundColor: "#fff",
    borderColor: "#000",
    "&:hover": {
      borderColor: "#000",
      backgroundColor: "#FFE500",
      borderRightColor: "#000 !important",
    },
  },
  tabSelected: {
    backgroundColor: "#FFE500",
  },
  tabContent: {
    mt: 5,
  },
  tabCol1: {
    p: 3,
    minHeight: "100%",
    backgroundColor: "#FFE500",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid #000",
  },
  tabCol2: {
    minHeight: "100%",
    backgroundColor: "#fff",
  },
  textIcon: {
    maxHeight: "16px",
    height: "100%",
    mb: "2px",
    mr: 1,
  },
  headerContainer: {
    alignSelf: "center",
    [BP1]: {
      margin: "32px 16px",
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.black,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    border: "1px solid rgba(0,0,0,1)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  color: "#000",
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const TabContent = ({ header, description, data }) => {
  return (
    <Grid container sx={sx.tabContent}>
      <Grid item xs={12} md={3} sx={sx.tabCol1}>
        <Typography sx={{ fontWeight: "700", fontSize: "20px", mb: 1 }}>
          {header}
        </Typography>
        <Typography>{description}</Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={sx.tabCol2}>
        <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
          <Table sx={{ minWidth: "100%" }} aria-label="customized table">
            <TableBody sx={{ display: { xs: "none", md: "contents" } }}>
              {data.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell
                    sx={{ width: "30%", fontWeight: 900 }}
                    component="th"
                    scope="row"
                  >
                    <Box component="img" sx={sx.textIcon} src={icon}></Box>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.description}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableBody sx={{ display: { xs: "block", md: "none" } }}>
              {data.map((row) => (
                <>
                  <StyledTableRow key={row.name}>
                    <StyledTableCell
                      sx={{ width: "30%", fontWeight: 900 }}
                      component="th"
                      scope="row"
                    >
                      <Box component="img" sx={sx.textIcon} src={icon}></Box>
                      {row.name}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {row.description}
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const DynamicNft = () => {
  const [tab, setTab] = useState(0);

  return (
    <Box sx={sx.root} id={"dynamic-nft"}>
      <Box sx={sx.container}>
        <Grid container>
          <Grid item sm={12} md={5} sx={sx.headerContainer}>
            <Typography variant="heading1" sx={sx.header}>
              DYNAMIC NFTs
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <PaperBox>
              <Typography>
                The Association NFTs are connected to live data feeds and
                computations for each respective team and player via Chainlink
                Oracle. This allows each player’s NFT appearance to change in an
                automated manner based upon the pre-set achievements that are
                written into the Galaxis smart contract.
                <br></br> <br></br>
                The more that a player accomplishes during the 2022 NBA
                Playoffs, the more visual changes their NFT will receive.
                <br></br> <br></br>
                Visual changes may include backgrounds, player accessories, and
                emojis designed by various artists.
              </Typography>
            </PaperBox>
          </Grid>
        </Grid>

        <Box sx={sx.content}>
          <Typography sx={sx.contentHeader} variant="heading1">
            ACHIEVEMENTS FOR THE 2022 NBA PLAYOFFS
          </Typography>

          <ButtonGroup sx={sx.tabContainer} variant="contained2" size="large">
            <Button
              sx={{
                ...sx.tabBtns,
                ...(tab == 0 && sx.tabSelected),
              }}
              onClick={() => setTab(0)}
            >
              PLAYER
            </Button>
            <Button
              sx={{ ...sx.tabBtns, ...(tab == 1 && sx.tabSelected) }}
              onClick={() => setTab(1)}
            >
              TEAM
            </Button>
          </ButtonGroup>
          {tab == 0 && (
            <TabContent
              header={"TRAITS AND ACCESSORIES"}
              description={
                <>
                  Traits and Accessories for each player's NFT can change based
                  upon the player's on-court performance and achievements.
                  <br></br>
                  <br></br>
                  Progressive traits change if the stat achievement is hit in 1+
                  games, 3+ games, and 5+ games.
                </>
              }
              data={playerTraits}
            />
          )}

          {tab == 1 && (
            <TabContent
              header={"BACKGROUND & FRAMES"}
              description={
                "Backgrounds and Frames can change for each player's NFT across entire team rosters based upon the team's advancement in the 2022 NBA Playoffs. "
              }
              data={teamTraits}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DynamicNft;
