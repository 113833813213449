import { useMemo } from "react";

import { Box } from "@mui/system";
import dot from "../../assets/images/dot-shadow.svg";

const sx = {
  root: {
    width: "100%",
    backgroundSize: "20px 20px",
    backgroundImage: `
        linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)`,
    border: "2px solid black",
    borderRadius: "2px",
    backgroundColor: "#fff",
    px: 2,
    pb: 2,
  },
  dotsContainer: {
    width: "100%",
    height: "30px",
    display: "flex",
    my: 2,
    overflow: "hidden",
  },
  dot: {
    height: "20px",
    width: "20px",
    mx: "auto",
  },
};

const PaperBox = ({ children, dotAmount }) => {
  const dots = useMemo(() => {
    const dots = [];
    let amount = 10;
    if (dotAmount) {
      amount = dotAmount;
    }
    for (var i = 0; i < amount; i++) {
      dots.push(
        <Box key={"_" + i} component="img" src={dot} sx={sx.dot}></Box>
      );
    }
    return dots;
  }, [dotAmount]);
  return (
    <Box sx={sx.root}>
      <Box sx={sx.dotsContainer}>{dots}</Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default PaperBox;
