// Builtin Imports
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import RegionLocked from './components/RegionLocked/RegionLocked';

// Internal Imports
import './App.css';
import AppCtx from './context/AppCtx';
import Web3Manager from './components/Web3Manager/Web3Manager';
import routes from './Routes';
import theme from './theme';
import config from './config';
import countryDenyList from './components/Home/constant/countryDenyList';

// Third Party Imports
import axios from 'axios';
import { ToastContainer } from 'react-toast';
import { Box, ThemeProvider } from '@mui/material';
import { MoralisProvider } from 'react-moralis';

const sx = {
  root: {
    position: 'relative',
    minHeight: '100%',
    overflow: 'hidden',
  },
  pageRoot: {
    width: '100%',
    transition: 'all .3s',
  },
  content: {
    width: '100%',
    minHeight: 'calc(100vh - 138px)',
    mx: 'auto',
    transition: 'all .3s',
    px: '56px',
  },
};

function App() {
  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(false);
  const [allow, setAllow] = useState(true);
  const [userCountryCode, setUserCountryCode] = useState(null);
  const baseServer = config.BASE_SERVER;

  useEffect(() => {
    fetchUserCountry();
  }, []);

  useEffect(() => {
    if (userCountryCode) {
      const blocked = Object.keys(countryDenyList).includes(userCountryCode);
      setAllow(!blocked);
    }
  }, [userCountryCode]);

  const fetchUserCountry = async () => {
    const res = await axios
      .get(`${baseServer}/api/location`)
      .catch((e) => console.log(e));
    if (res && res.data && res.data.country_code) {
      const countryCode = res.data.country_code;
      setUserCountryCode(countryCode);
    } else {
      setUserCountryCode(null);
    }
  };

  return (
    <MoralisProvider
      appId={config.MORALIS_APP_ID}
      serverUrl={config.MORALIS_SERVER_URL}
    >
      <Web3Manager>
        <AppCtx.Provider
          value={{ txInProgress, setTxInProgress, txEtherScan, setTxEtherScan }}
        >
          <ThemeProvider theme={theme}>
            <Box sx={sx.root}>
              <HashRouter basename="/">
                {/* <BrowserRouter basename="/"> */}
                <Switch>
                  {routes.map((route, key) => (
                    <Route
                      key={key.toString()}
                      path={route.path}
                      component={allow ? route.component : RegionLocked}
                      exact={route.exact}
                    />
                  ))}
                </Switch>
                <ToastContainer delay={4000} position="bottom-right" />
                {/* </BrowserRouter> */}
              </HashRouter>
            </Box>
          </ThemeProvider>
        </AppCtx.Provider>
      </Web3Manager>
    </MoralisProvider>
  );
}

export default App;
