import { Box, width, Typography, Modal, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FilterItem from './FilterItem';
import close from '../../../../assets/images/close-button.png';
import FilterDataDiplayer from './FilterDataDiplayer';
const sx = {
  content: {
    padding: '32px 16px',
    overflowY: 'scroll',
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mb: '24px',
  },
  titleBox: {},
  title: {
    fontFamily: 'abc-grav',
    fontSize: '24px',
    lineHeight: '24px',
    color: '#fff',
  },
  filterBox: {
    width: '100%',
  },
  btn: {
    position: 'sticky',
    maxWidth: '100%',
    width: '100%',
    bottom: '5%',
    transform: 'translate(0%, 90%)',
  },
  btnOpensea: {
    maxWidth: '100%',
    width: '100%',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    flexWrap: 'wrap',
    mb: '24px',
  },
  clearButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: '18px',
  },
};
const MobileFilter = ({
  filterData,
  openFilter,
  setOpen,
  selectedFilters,
  activeFilters,
  handleCheckBoxChanges,
  clearOne,
  clearAll,
  setFilterCounter,
}) => {
  const [mobileView, setMobileView] = useState(false);
  console.log(selectedFilters);
  useEffect(() => {
    if (window.innerWidth < 600) {
      setMobileView(true);
    }
  }, []);
  const handleFilterCount = () => {
    setFilterCounter(selectedFilters.length);
  };
  const createLink = () => {
    let links = '';
    selectedFilters.map((selected, index) => {
      links =
        links +
        `search[stringTraits][${index}][name]=${Object.keys(
          selected
        )}&search[stringTraits][${index}][values][0]=${Object.values(
          selected
        )}&`;
      links = links.replace('+', '%2B');
    });
    window.open(
      `https://opensea.io/collection/the-association-nft?search[sortAscending]=true&search[sortBy]=PRICE&${links}`,
      '_blank'
    );
  };
  return (
    <>
      <Modal
        open={openFilter}
        // onClose={(event, reason) => {
        //   //   if (reason !== 'backdropClick') {
        //   //     setOpen(false);
        //   //   }
        //   setOpen(false);
        // }}
        style={{
          background: '#102F5E',
          webkitBackgroundSize: '30px 30px',
          backgroundSize: '30px 30px',
          backgroundImage:
            'linear-gradient(to right, black 1px, transparent 1px), linear-gradient(to bottom, black 1px, transparent 1px)',
        }}
      >
        <Box sx={sx.content}>
          <Box sx={sx.header}>
            <Box sx={sx.titleBox}>
              <Typography sx={sx.title}>FILTERS</Typography>
            </Box>
            <img
              src={close}
              alt="close-button"
              onClick={() => setOpen(false)}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          {selectedFilters.length > 0 && (
            <Box sx={sx.clearButton}>
              <Typography sx={sx.title} style={{ fontSize: '16px' }}>
                Used filters:
              </Typography>
              <Button
                onClick={() => {
                  clearAll();
                }}
                style={{
                  fontFamily: 'abc-grav',
                  fontSize: '18px',
                }}
              >
                Clear All
              </Button>
            </Box>
          )}

          {selectedFilters.length > 0 && (
            <Box id="render-selected-datas" sx={sx.filterContainer}>
              {mobileView &&
                selectedFilters.map((elem, index) => {
                  return (
                    <FilterDataDiplayer
                      elem={elem}
                      key={index}
                      clearOne={clearOne}
                    />
                  );
                })}
            </Box>
          )}
          {filterData &&
            Object.keys(filterData).map((keyname, index) => {
              return (
                <Box sx={sx.filterBox}>
                  <FilterItem
                    filterTitle={keyname}
                    filters={filterData[keyname]}
                    index={index}
                    selectedFilters={selectedFilters}
                    handleCheckBoxChanges={handleCheckBoxChanges}
                  />
                </Box>
              );
            })}
          <Button
            variant="contained3"
            sx={sx.btnOpensea}
            style={{ top: '0px' }}
            onClick={() => createLink()}
          >
            Opensea Filters
          </Button>
          <Button
            variant="contained3"
            sx={sx.btn}
            style={{ top: '0px' }}
            onClick={() => {
              handleFilterCount();
              setOpen(false);
            }}
          >
            Apply Filters
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default MobileFilter;
