import { useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import { LazyImage } from '../../../common/LazyImage';
import { Box, Typography } from '@mui/material';

const sx = {
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
    ['img']: {
      maxWidth: '100%',
    },
    '&:hover': {
      position: 'relative',
      transform: 'translateY(-10px)',
    },
  },
  cardContent: {
    width: '100%',
    mt: '0',
    zIndex: '1',
  },
  cardDescription: {
    fontFamily: 'abc-grav',
    fontWeight: '900',
    fontSize: '18px',
    lineHeight: '32px',
    color: '#FFFF',
  },
};

const ExplorerCard = ({ card }) => {
  const [loading, setLoading] = useState(true);
  const openOpensea = (id) => {
    if (id >= 18000) {
      window.open(
        `https://opensea.io/assets/ethereum/0xd7bea2b69c7a1015aadaa134e564eee6d34149c0/${id}`,
        '_blank'
      );
    } else {
      window.open(
        `https://opensea.io/assets/ethereum/0x9b8b9c7c02be0bd0aa4d669bf6a1f6003424c6dc/${id}`,
        '_blank'
      );
    }
  };
  //   const CardDetails = () => {
  //     return <div className="card-details">
  //       <ul>
  //       {card.attributes.map((attribute)=>{
  //         return <li style={{paddingTop:"15px"}} key={attribute.trait_type}>
  //           <p className='trait-type'>{ Object.values(attribute)[1]}</p>
  //           <p className='trait-value'>{ Object.values(attribute)[0]}</p>
  //         </li>
  //       })}
  //       </ul>
  //     </div>;
  //   };

  //   const choseCards = () =>{
  //     setTheChosenCard(card)
  //     showOverlayForCard(true);
  //   }

  return (
    <Box
      className="root"
      // onClick={choseCards}
    >
      <Box sx={sx.imageContainer} className="image-container">
        <Box
          style={{ position: 'relative', minHeight: '100px' }}
          onClick={() => openOpensea(card.tokenId)}
        >
          <SpinnerCircular
            color="#000"
            style={{
              display: loading ? 'block' : 'none',
              margin: 'auto',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
          <LazyImage
            src={card.image}
            imageOnLoad={() => setLoading(false)}
            alt="Card"
          />
        </Box>
        {!loading && (
          <Box sx={sx.cardContent} style={{ zIndex: '1' }}>
            <Typography sx={sx.cardDescription}>{card.name}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ExplorerCard;
