import { Box, width, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import FilterItem from './FilterItem';
const sx = {
  titleBox: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    pl: '26px',
    pt: '24px',
  },
  title: {
    fontFamily: 'abc-grav',
    fontSize: '28px',
    lineHeight: '33.78px',
  },
  filterBox: {
    maxWidth: '314px',
    minWidth: '280px',
  },
  btn: {
    width: '100%',
    mb: '10px',
    py: '2px',
  },
};
const Filter = ({ filterData, handleCheckBoxChanges, selectedFilters }) => {
  const createLink = () => {
    let links = '';
    selectedFilters.map((selected, index) => {
      links =
        links +
        `search[stringTraits][${index}][name]=${Object.keys(
          selected
        )}&search[stringTraits][${index}][values][0]=${Object.values(
          selected
        )}&`;
      links = links.replace('+', '%2B');
    });
    window.open(
      `https://opensea.io/collection/the-association-nft?search[sortAscending]=true&search[sortBy]=PRICE&${links}`,
      '_blank'
    );
  };
  // console.log
  return (
    <>
      <Box style={{ maxWidth: '314px', width: '100%' }}>
        <Button onClick={() => createLink()} sx={sx.btn} variant="contained3">
          Check it on OpenSea
        </Button>
        <Box sx={sx.titleBox}>
          <Typography sx={sx.title}>FILTERS</Typography>
        </Box>
        {filterData &&
          Object.keys(filterData).map((keyname, index) => {
            return (
              <Box sx={sx.filterBox}>
                <FilterItem
                  filterTitle={keyname}
                  filters={filterData[keyname]}
                  index={index}
                  handleCheckBoxChanges={handleCheckBoxChanges}
                  selectedFilters={selectedFilters}
                />
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default Filter;
