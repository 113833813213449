import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { SALE_STATUS } from "../../../hooks/useSaleInfo";
import { navigate } from "../../../hooks/useNavigate";

import associationBanner from "../../../assets/images/association-banner.svg";
import nbaLogo from "../../../assets/images/nba-logo.svg";
import circularLogo from "../../../assets/images/circular-logo.svg";
import termsBackground from "../../../assets/images/termsBackground.png";

import { Box, Grid, Typography } from "@mui/material";

const BP1 = "@media (max-width: 599px)";
const BP2 = "@media (max-width: 899px)";
const BP3 = "@media (max-width: 1199px)";
const BP4 = "@media (max-width: 1300px)";
const BP5 = "@media (max-width: 1500px)";

const sx = {
  root: {
    width: "100%",
    height: "100%",
    // marginBottom: "35px",
    [BP3]: {
      marginBottom: "0px",
    },
  },
  gridContainer: {
    height: "100%",
    background: "rgba(40, 40, 40, 0.8);",
    paddingLeft: "30px",
    paddingBottom: "30px",
    background: `linear-gradient(135deg, transparent 21px, rgba(40, 40, 40, 0.8) 0) top left,
          linear-gradient(-135deg, transparent 0, rgba(40, 40, 40, 0.8) 0) top right,
          linear-gradient(-45deg, transparent 21px, rgba(40, 40, 40, 0.8) 0) bottom right,
          linear-gradient(45deg, transparent 0, rgba(40, 40, 40, 0.8) 0) bottom left`,
    backgroundSize: "50% 50%",
    backgroundRepeat: "no-repeat",
    [BP3]: {
      margin: "20px 0",
    },
  },
  bannerImg: {
    margin: "auto",
    width: "90%",
  },
  bannerImg2: {
    margin: "auto",
    maxWidth: "25%",
    maxHeight: "100%",
    [BP2]: {
      maxWidth: "auto",
      marginTop: "5%",
      marginRight: "20px",
    },
  },
  bannerImg3: {
    margin: "auto",
    maxWidth: "65%",
    maxHeight: "100%",
    marginLeft: "10%",
    [BP2]: {
      maxWidth: "auto",
      marginLeft: "20px",
      marginTop: "5%",
    },
  },
  col: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  col1Top: {
    display: "flex",
    height: "35%",
    width: "100%",
    background: "#FFE500",
    [BP2]: {
      height: "auto",
      padding: "10px",
    },
  },
  col1Bottom: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "65%",
    width: "100%",
    background: `#000 url(${termsBackground})`,
    padding: "15px",
    [BP2]: {
      height: "auto",
    },
  },
  col2Top: {
    display: "flex",
    flexDirection: "column",
    height: "75%",
    margin: "5%",
    padding: "1%",
    background: "#fff",
    [BP2]: {
      margin: "0",
      padding: "0",
    },
  },
  col2Bottom: {
    display: "flex",
    [BP2]: {
      flexDirection: "column",
    },
  },
  logoContainer: {
    background: "#143B75",
    padding: "25px",
    display: "flex",
  },
  licenseContainer: {
    background: "#FFE500",
    padding: "40px 20px",
  },
  licenseText: {
    fontFamily: "abc-grav",
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "22px",
  },
  bannerText: {
    fontSize: "1.4rem",
    textAlign: "center",
    color: "#fff",
    [BP1]: {
      fontSize: "18px",
    },
  },
  infoBox: {
    margin: "auto",
    marginTop: "20px",
    background: "#000",
    border: "2px solid #fff",
    textAlign: "center",
    maxWidth: "80%",
    justifyContent: "center",
    [BP2]: {
      border: "0px",
    },
  },
  infoText: {
    fontSize: "24px",
    fontFamily: "digital",

    [BP1]: {
      fontSize: "16px",
    },
  },
  timerContainer: {
    display: "flex",
    flexDirection: "column",
    height: "60%",
    width: "100%",
    background: "#000",
    border: "2px solid #fff",
    justifyContent: "center",
  },
  countdown: {
    fontFamily: "digital",
    fontSize: "65px",
    lineHeight: "82px",
    textAlign: "center",
    color: "#FF0000",
    whiteSpace: "nowrap",
    [BP4]: {
      fontSize: "57px",
    },
    [BP2]: {
      fontSize: "65px",
    },
    [BP1]: {
      fontSize: "55px",
    },
  },
  mintTracker: {
    fontFamily: "digital",
    fontSize: "45px",
    lineHeight: "62px",
    textAlign: "center",
    color: "#fff",
    whiteSpace: "nowrap",
    wordSpacing: "20px",
    [BP2]: {
      fontSize: "65px",
      lineHeight: "82px",
    },
    [BP1]: {
      fontSize: "45px",
    },
  },
  countdownLabel: {
    color: "#fff",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "digital",
    fontWeight: "normal",
    [BP5]: {
      fontSize: "18px",
    },
    [BP4]: {
      fontSize: "16px",
    },
    [BP3]: {
      fontSize: "15px",
    },
    [BP2]: {
      fontSize: "20px",
    },
    [BP1]: {
      fontSize: "18px",
    },
  },
  timerText: {
    color: "#000",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
    mt: 3,
    textTransform: "uppercase",
    paddingBottom: "6px",
    marginTop: "10px",
  },
  faqLink: {
    color: "#000",
    fontSize: "12px",
    lineHeight: "14px",
    textTransform: "uppercase",
    textAlign: "center",
    textDecoration: "underline",
    transition: "all 0.1s ease-in-out",
    cursor: "pointer",
    fontWeight: "900",
    fontFamily: "abc-grav",
    paddingBottom: "12px",
    "&:hover": {
      opacity: 0.8,
    },
  },
};

const Banner = ({
  countdown,
  saleStatus,
  maxMintSale,
  userMinted,
  nftRevealPopup,
  allowListPopup,
}) => {
  const history = useHistory();

  const nftCount = {
    minted: userMinted || 0,
    total: 30000,
  };

  function padWithZero(num, targetLength) {
    return String(num).padStart(targetLength.toString().length, "0");
  }

  const bannerCountdownText =
    saleStatus == SALE_STATUS.PRESALE
      ? "UNTIL ALLOW LIST MINT CLOSES"
      : saleStatus == SALE_STATUS.SALE ||
        saleStatus == SALE_STATUS.PRESALE_ENDED
      ? "UNTIL NFT REVEAL"
      : saleStatus == SALE_STATUS.SALE_ENDED
      ? "MINT CLOSED"
      : saleStatus == SALE_STATUS.AIRDROP
      ? ""
      : "UNTIL ALLOW LIST MINT";

  const faqLink =
    saleStatus == SALE_STATUS.PRESALE ||
    saleStatus == SALE_STATUS.UPCOMING_PRESALE
      ? "WHAT IS THE ALLOW LIST?"
      : saleStatus == SALE_STATUS.SALE
      ? `WHAT IS THE NFT REVEAL?`
      : "";

  const handleFaqLink = () => {
    if (
      saleStatus == SALE_STATUS.PRESALE ||
      saleStatus == SALE_STATUS.UPCOMING_PRESALE
    ) {
      allowListPopup();
    } else if (saleStatus == SALE_STATUS.SALE) {
      nftRevealPopup();
    }
  };

  return (
    <Box sx={sx.root}>
      <Grid container sx={sx.gridContainer}>
        <Grid item sx={sx.row} xs={12} md={7}>
          <Box sx={sx.col}>
            <Box sx={sx.col1Top}>
              <Box
                component="img"
                sx={sx.bannerImg}
                src={associationBanner}
              ></Box>
            </Box>
            <Box sx={sx.col1Bottom}>
              <Typography variant="heading1" sx={sx.bannerText}>
                Dynamic NFTs for each player in the 2022 NBA Playoffs.
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={sx.infoBox}>
                    <Typography
                      variant="heading1"
                      sx={{ ...sx.infoText, color: "#FF0000" }}
                    >
                      125 EDITIONS PER PLAYER
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={sx.infoBox}>
                    <Typography
                      variant="heading1"
                      sx={{ ...sx.infoText, fontSize: "20px", color: "#fff" }}
                    >
                      FREE TO MINT
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item sx={sx.row} xs={12} md={5}>
          <Box sx={sx.col}>
            <Box sx={sx.col2Top}>
              <Box sx={sx.timerContainer}>
                <Typography sx={sx.countdown}>
                  {saleStatus != SALE_STATUS.AIRDROP ? (
                    <>
                      {countdown.days} : {countdown.hours} : {countdown.minutes}
                    </>
                  ) : (
                    <>AIRDROP</>
                  )}
                </Typography>
                <Grid container>
                  {saleStatus != SALE_STATUS.AIRDROP && (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="heading1" sx={sx.countdownLabel}>
                          Days
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="heading1" sx={sx.countdownLabel}>
                          Hrs
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="heading1" sx={sx.countdownLabel}>
                          Min
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
              {bannerCountdownText != "" && (
                <Typography variant="heading1" sx={sx.timerText}>
                  {bannerCountdownText}
                </Typography>
              )}
              {faqLink != "" && (
                <Typography sx={sx.faqLink} onClick={() => handleFaqLink()}>
                  {faqLink}
                </Typography>
              )}
              <Box sx={sx.timerContainer}>
                <Typography sx={sx.mintTracker}>
                  <Grid container>
                    <Grid item xs={6}>
                      {padWithZero(nftCount.minted, nftCount.total)}{" "}
                    </Grid>
                    <Grid item xs={6}>
                      {nftCount.total}
                    </Grid>
                  </Grid>
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="heading1" sx={sx.countdownLabel}>
                      Minted
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="heading1" sx={sx.countdownLabel}>
                      Total
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={sx.col2Bottom}>
              <Grid item xs={12} md={5} sx={sx.logoContainer}>
                <Box component="img" sx={sx.bannerImg2} src={nbaLogo}></Box>
                <Box
                  component="img"
                  sx={sx.bannerImg3}
                  src={circularLogo}
                ></Box>
              </Grid>
              <Grid item xs={12} md={7} sx={sx.licenseContainer}>
                <Typography sx={sx.licenseText}>
                  A COLLECTION OF DYNAMIC NFTS BROUGHT TO YOU BY THE NBA AND
                  NBPA.
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Banner;
