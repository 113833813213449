import { Box, Typography, Button } from "@mui/material";
import CollapsibleCard from "../../common/CollapsibleCard";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useState } from "react";

const sx = {
  root: {
    position: 'relative',
    background: '#000',
    minHeight: '100vh',
  },
  container: {
    padding: '2%',
    background: '#000',
    margin: '0px !important',
  },
  headerContainer: {
    display: 'flex',
  },
  header: {
    background: '#FFE500',
    width: '304px !important',
    fontSize: '66px',
    lineHeight: '72px',
    fontFamily: 'abc-grav',
    textAlign: 'center',
    borderRadius: '2px',
    marginBottom: '46px'
  },
  divider: {
    position: 'static',
    width: '100%',
    height: '0px',
    left: '0px',
    top: '0px',
    borderTop: '4px solid #FFE500',
    display: 'inline-flex',
    height: '0px',
    marginTop: '34px',
  },
  fullFaqList: {
    padding: 0,
  },
  fullFaqListContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    padding: 0,
    background: '#000',
    margin: '0px !important',
  },
  showMoreBtnContainer: {
    padding: '0px',
    width: 'fit-content',
    margin: "auto"
  },
  showMoreBtn: {
    background: '#fff',
    borderColor: '#000',
    color: "#000",
    fontSize: "18px",
    "&:hover": {
      background: '#fff',
      borderColor: '#000',
      color: "#000",
    },
  }
};

const Faq = () => {
  const [expanded, setExpanded] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const handleChange = () => (event) => {
    setExpanded(!expanded);
  };
  const showMoreChange = () => (event) => {
    setShowMore(!showMore);
  }
  return (
    <Accordion
      sx={sx.container}
      expanded={expanded === true}
      onChange={handleChange()}
      id={"faq"}
    >
      <AccordionSummary sx={sx.headerContainer}>
        <Typography sx={sx.header}>
          FAQs
        </Typography>
        <Box sx={sx.divider} />
        {
          expanded ? (
            <ExpandMoreIcon
              style={{ color: '#020100', width: '100px', height: '72px', background: '#FFE500', borderRadius: '2px', padding: '10px' }}
            />
          ) : (
            <ExpandLessIcon
              style={{ color: '#020100', width: '100px', height: '72px', background: '#FFE500', borderRadius: '2px', padding: '10px' }}
            />
          )
        }

      </AccordionSummary>
      <AccordionDetails>
        <CollapsibleCard title={"What is The Association NFT?"} index={0} name={'card'}
          body={"The Association NFT is a collection of dynamic NFTs that change in appearance over time based on team and player on-court performance. There will be 30,000 total NFTs minted for the 2022 NBA Playoffs (125 NFTs for each of the 240 rostered players)."} />
        <CollapsibleCard title={"Where can I view my NFT after I’ve minted it?"} index={1} name={'card'}
          body={"After minting, you can view your NFT in your wallet, on TheAssociationNFT.com and on secondary marketplaces, like OpenSea."} />
        <CollapsibleCard title={"How will players be assigned to The Association NFTs after they are minted?"} index={2} name={'card'}
          body={"After the entire collection has been minted, players will be assigned randomly and transparently using Chainlink VRF. Everyone has a fair and equal chance of minting any player from any of the 16 Playoff teams. "} />
        <CollapsibleCard title={"When will The Association NFT collection be revealed?"} index={3} name={'card'}
          body={"The players will be revealed on Friday, April 22."} />
        <CollapsibleCard title={"If a player has achieved an accomplishment before the NFT is revealed, will it still display dynamic elements?"} index={4} name={'card'}
          body={"Yes, accomplishments achieved by a player before Friday, April 22 will be included in that player's NFT at the time of reveal."} />
        <Accordion
          sx={sx.fullFaqListContainer}
          expanded={showMore === true}
          onChange={showMoreChange()}
          id={"fullFaq"}
        >
          <AccordionSummary sx={sx.showMoreBtnContainer}>
            {!showMore ? (
              <Button variant="outlined" sx={sx.showMoreBtn}>
                Show All
              </Button>
            ) : (
              <Button variant="outlined" sx={sx.showMoreBtn}>
                Show Less
              </Button>
            )
            }
          </AccordionSummary>
          <AccordionDetails sx={sx.fullFaqList}>
            <CollapsibleCard title={"Can anyone mint The Association NFT?"} index={5} name={'card'}
              body={"To mint The Association NFT, you need to be on the allow list. Spots on the allow list were made available to the earliest members of NBA Discord. To confirm your spot on the allow list, connect your wallet on TheAssociationNFT.com website or head to NBA Discord and enter your wallet address in the # allow-list-check channel."} />
            <CollapsibleCard title={"How long does it take for the NFT to move into my wallet?"} index={6} name={'card'}
              body={"Typically, a transaction is completed within 1-5 minutes."} />
            <CollapsibleCard title={"How much does it cost to mint The Association NFTs?"} index={7} name={'card'}
              body={"The 2022 NBA Playoffs Association NFTs are free to mint (plus applicable gas fees)."} />
            <CollapsibleCard title={"Are there different rarities within the collection?"} index={8} name={'card'}
              body={"Each player NFT will have five possible designs with specific rarities, which will be randomly and transparently assigned when minted on the Ethereum blockchain using Chainlink VRF. Designs for each player feature a unique combination of team uniforms and frames."} />
            <CollapsibleCard title={"How does the dynamic NFT work?"} index={9} name={'card'}
              body={"The Association NFTs are connected to on-court data and computations via Chainlink Oracle. This allows the NFT to change in an automated manner based upon the pre-set achievements that are written into the Galaxis smart contract."} />
            <CollapsibleCard title={"What is the definition of 'clutch' in the 'Ice In His Veins' achievement?"} index={10} name={'card'}
              body={"'Clutch' is defined as any time when the score is within five points in the final five minutes of the fourth quarter or any overtime period."} />
            <CollapsibleCard title={"How are Fantasy Points calculated?"} index={11} name={'card'}
              body={"The formula to calculate Fantasy Points is: Points + (Rebounds * 1.2) + (Assists * 1.5) +  (Steals * 3) +  (Blocks * 3) + (Turnovers * -1)"} />
            <CollapsibleCard title={"How is the top highlight for each series determined for the 'Highlight Reel' achievement?"} index={12} name={'card'}
              body={`After a series concludes, a Twitter poll will go live on @NBAxNFT for the top highlights from that series. The player highlight which receives the most votes in 24 hours will recieve the "highlight reel" achievement for that player's Association NFT.`} />
            <CollapsibleCard title={"Do I own the rights to the artwork in my NFT?"} index={13} name={'card'} link={'/nft-terms-and-conditions'}
              body={"<span>The Association NFT Terms and Conditions can be found <a href='/nft-terms-and-conditions'>[here]</a>.</span>"} />
            {/* <CollapsibleCard title={"How are the serial numbers issued?"} index={14} name={'card'}
              body={"PLaceholder"} /> */}
            <CollapsibleCard title={"Can I purchase more than one?"} index={15} name={'card'}
              body={"Only one NFT can be minted per wallet."} />
            {/* <CollapsibleCard title={"What is The Association NFT's smart contract address?"} index={16} name={'card'}
              body={"PLaceholder"} /> */}
            <CollapsibleCard title={"Will any of The Association NFTs be reserved for the NBA?"} index={17} name={'card'}
              body={"480 total NFTs will be reserved and minted for the NBA and NBPA."} />
            <CollapsibleCard title={"Will any of The Association NFTs be reserved for the NBA Top Shot Community?"} index={18} name={'card'}
              body={"<span>The NBA and NBPA are reserving 1,900 NFTs from The Association collection for the NBA Top Shot community. Visit NBA Top Shot for additional details <a href='https://blog.nbatopshot.com/' target='_self'>https://blog.nbatopshot.com/</a>.</span>"} />
            <CollapsibleCard title={"What is a wallet?"} index={19} name={'card'}
              body={"<span>To purchase and store The Association NFT, collectors must use a digital asset wallet, like Coinbase Wallet. To set your digital asset wallet up, you can navigate to <a href='https://www.coinbase.com/wallet' target='_self'>https://www.coinbase.com/wallet</a> and follow the setup instructions.</span>"} />
            <CollapsibleCard title={"What are Gas Fees?"} index={20} name={'card'}
              body={"<span>“Gas fees” are the transaction fees that users pay miners on the blockchain to have their transactions processed (i.e. included in the block). On the Ethereum blockchain, gas fees are paid using ETH cryptocurrency. The amount of gas you'll need to pay to have your transaction processed will depend on the amount of people that are trying to process transactions on the network at the same time. There are websites where you can track gas prices, such as: <a href='https://etherscan.io/gastracker' target='_self'>https://etherscan.io/gastracker</a>.</span>"} />
            <CollapsibleCard title={"Where can I get ETH cryptocurrency?"} index={21} name={'card'}
              body={"You can purchase ETH using your native currency on an exchange platform, like Coinbase."} />
            <CollapsibleCard title={"What is Chainlink VRF?"} index={22} name={'card'}
              body={"Chainlink VRF (Verifiable Random Function) is a provably fair and verifiable random number generator (RNG) that enables smart contracts to access random values without compromising security or usability."} />
            <CollapsibleCard title={"Where can I connect with other fans that have minted The Association NFT?"} index={23} name={'card'}
              body={"Join NBA Discord (discord.gg/nba) and follow @NBAxNFT on Twitter to engage, interact, and learn more about current and future NBA projects and partnerships. "} />
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};

export default Faq;
