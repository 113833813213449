import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import ConnectWalletBtn from '../../common/ConnectWalletBtn';
import associationBanner from '../../../assets/images/association-header.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { navigate } from '../../../hooks/useNavigate';

const BP1 = '@media (max-width: 1199px)';
const BP2 = '@media (max-width: 1400px)';
const polygon = (cornerSize) => {
  return `polygon(
    0 ${cornerSize},     /* top left */
    ${cornerSize} 0,     /* top left */
    100% 0,    /* top right */
    100% 0,   /* top right */
    100% calc(100% -  ${cornerSize}),  /* bottom right */
    calc(100% -  ${cornerSize}) 100%,  /* bottom right */
    0 100%,   /* bottom left */
    0 100%      /* bottom left */
    )`;
};

const sx = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#000000',
    height: '94px',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: 1000,
    transition: 'transform 0.5s',
    [BP1]: {
      transition: 'transform 0s',
    },
  },
  associationLogoContainer: {
    display: 'flex',
  },
  linkContainer: {
    display: { md: 'none', lg: 'flex' },
    [BP2]: {
      display: 'none',
    },
  },
  headerImage1: {
    width: '280px',
    height: '40px',
    margin: 'auto',
    marginLeft: '52px',
    cursor: 'pointer',
  },
  btn: {
    width: '145px',
    height: '50px',
    margin: 'auto 7px',
    fontSize: '15px',
    padding: '5px',
  },
  connectBtn: {
    width: '225px',
    height: '64px',
    fontSize: '20px',
    color: '#fff',
    margin: 'auto',
    marginRight: '36px !important',
    border: '2px solid #FFE500',
    background: '#000',
    '&:hover': {
      background: '#000',
    },
  },
  popUpconnectBtn: {
    width: '169px',
    height: '50px',
    fontSize: '16px',
    color: '#fff',
    margin: 'auto 10px',
    border: '2px solid #FFE500',
    background: '#000',
    '&:hover': {
      background: '#000',
    },
  },
  menuContainer: {
    display: { lg: 'none' },
    [BP2]: {
      display: 'flex',
    },
  },
  menuIcon: {
    color: '#020100',
    width: '50px',
    height: '50px',
    color: '#FFF',
    margin: 'auto',
    marginRight: '20px',
  },
  popUp: {
    display: 'none',
  },
  popupContainer: {
    position: 'absolute',
    backgroundColor: 'black',
    width: '238px',
    right: 0,
    top: 94,
    zIndex: 10,
    clipPath: polygon('35px'),
    transformOrigin: '100% 0%',
    transform: 'scale(1)',
    transition: 'all 0.4s ease-in-out',
    p: '25px',
  },
};

const Popup = ({ showPopup, setShowPopup, children }) => {
  return (
    <Box
      sx={{
        ...sx.popupContainer,
        ...(showPopup ? { transform: 'scale(1)' } : { transform: 'scale(0)' }),
      }}
    >
      {children}
    </Box>
  );
};

const Header = () => {
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    const { hash } = history.location;
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    window.addEventListener('resize', closePopUp);
    const hashPath = hash.substr(1);
    if (hash && document.getElementById(hashPath)) {
      // Check if there is a hash and if an element with that id exists
      var topOfElement = document.getElementById(hashPath)?.offsetTop;
      window.scroll({ top: topOfElement, behavior: 'smooth' });
    } else {
      window.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [history.location.hash]);

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname == '/wallet' || pathname == '/explorer') {
      setScrollTop(true);
    }
  }, [history.location.pathname]);

  const handleScroll = () => {
    const { pathname } = history.location;
    if (pathname != '/wallet' && pathname != '/explorer') {
      let scrollTops = window.scrollY;
      let clientWidth = window.innerWidth;
      if (scrollTops > 750 || clientWidth < 1200) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    }
  };

  const closePopUp = () => {
    setShowPopup(false);
  };

  return (
    <Box
      sx={sx.root}
      style={{ transform: scrollTop ? 'unset' : 'translate3d(0, -100%, 0)' }}
    >
      <Box sx={sx.associationLogoContainer}>
        <Box
          component="img"
          sx={sx.headerImage1}
          src={associationBanner}
          onClick={() => navigate(history, '/')}
        ></Box>
      </Box>
      <Box sx={sx.linkContainer}>
        <Button
          sx={sx.btn}
          variant="contained2"
          onClick={() => navigate(history, '/#about')}
        >
          About
        </Button>
        <Button
          sx={sx.btn}
          variant="contained2"
          onClick={() => navigate(history, '/#dynamic-nft')}
        >
          DYNAMIC NFTS
        </Button>
        <Button
          sx={sx.btn}
          variant="contained2"
          onClick={() => navigate(history, '/explorer')}
        >
          EXPLORER
        </Button>
        <Button
          sx={sx.btn}
          variant="contained2"
          onClick={() => navigate(history, '/#faq')}
        >
          FAQ
        </Button>

        <Button
          sx={sx.btn}
          variant="contained2"
          onClick={() => navigate(history, '/wallet')}
        >
          WALLET
        </Button>
      </Box>
      <Box sx={sx.linkContainer}>
        <ConnectWalletBtn sxStyle={sx.connectBtn} variant="contained2">
          CONNECT WALLET
        </ConnectWalletBtn>
      </Box>
      <Popup sx={sx.popUp} setShowPopup={setShowPopup} showPopup={showPopup}>
        <Box>
          <Button
            sx={sx.btn}
            variant="contained2"
            onClick={() => navigate('/#about')}
          >
            About
          </Button>
          <Button
            sx={sx.btn}
            variant="contained2"
            onClick={() => navigate('/#dynamic-nft')}
          >
            DYNAMIC NFTS
          </Button>
          <Button
            sx={sx.btn}
            variant="contained2"
            onClick={() => navigate(history, '/explorer')}
          >
            EXPLORER
          </Button>
          <Button
            sx={sx.btn}
            variant="contained2"
            onClick={() => navigate('/#faq')}
          >
            FAQ
          </Button>
          <Button
            sx={sx.btn}
            variant="contained2"
            onClick={() => navigate('/wallet')}
          >
            WALLET
          </Button>
          <ConnectWalletBtn sxStyle={sx.popUpconnectBtn} variant="contained2">
            CONNECT WALLET
          </ConnectWalletBtn>
        </Box>
      </Popup>
      <Box sx={sx.menuContainer}>
        <Button onClick={() => setShowPopup(!showPopup)}>
          <MenuIcon sx={sx.menuIcon} />
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
