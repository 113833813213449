import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useWeb3Ctx from '../../../hooks/useWeb3Ctx';
import useAllowList from '../../../hooks/useAllowList';
import useSaleInfo from '../../../hooks/useSaleInfo';
import { useCountdown } from '../../../hooks/useCountdown';
import { SALE_STATUS } from '../../../hooks/useSaleInfo';

import Banner from './Banner';
import ConnectWalletBtn from '../../common/ConnectWalletBtn';
import HeroBorderTexts from './HeroBorderTexts';
import HeroPopup, { STYLE } from './HeroPopup';

import { navigate } from '../../../hooks/useNavigate';

import discordIcon from '../../../assets/images/social/discordiconBlack.svg';
import discordIcon2 from '../../../assets/images/social/discordicon.svg';
import twitterIcon from '../../../assets/images/social/twitterBlack.svg';
import twitterIcon2 from '../../../assets/images/social/twitter.svg';
import openseaIcon from '../../../assets/images/opensea-logo.svg';
import openseaIcon2 from '../../../assets/images/opensea-logo-white.svg';
import heroAnimation1 from '../../../assets/images/heroanimation1.png';
import heroAnimation2 from '../../../assets/images/heroanimation2.png';

import { Box, Grid, Button } from '@mui/material';
import { keyframes } from '@emotion/react';

const background = 'linear-gradient(90deg, #899cba 50%, #fff 50%)';

const BP1 = '@media (max-width: 500px)';
const BP2 = '@media (max-width: 767px)';
const BP3 = '@media (max-width: 899px)';
const BP4 = '@media (max-width: 1020px)';
const BP5 = '@media (max-width: 1199px)';

const horizonatlAnimation = keyframes`
0% {
  transform: translate3d(-50%, 0, 0)
}
100% {
  transform: translate3d(0, 0, 0)
}
`;

const diagonalAnimation = keyframes`
0% {
  transform: translate3d(0, 0, 0)
}
100% {
  transform: translate3d(-50%, -75%, 0)
}
`;

const sx = {
  root: {
    position: 'relative',
    background: background,
    padding: '40px',
    overflow: 'hidden',
    [BP5]: {
      marginTop: '94px',
    },
  },
  container: {
    flexBasis: 'fit-content',
    margin: '0',
  },
  animationLayer1: {
    position: 'absolute',
    backgroundPosition: '0px 0px',
    backgroundRepeat: 'repeat-x',
    animation: `${horizonatlAnimation} 15s linear infinite alternate`,
    animationTimingFunction: 'ease-in-out',
    height: '100%',
    borderRadius: '18px',
    zIndex: 0,
    perspective: 1000,
    backfaceVisibility: 'hidden',
  },
  animationLayer2: {
    position: 'absolute',
    backgroundPosition: '0px 0px',
    backgroundRepeat: 'repeat-x',
    animation: `${diagonalAnimation} 20s linear infinite alternate`,
    animationTimingFunction: 'ease-in-out',
    height: '100%',
    borderRadius: '18px',
    zIndex: 0,
    perspective: 1000,
    backfaceVisibility: 'hidden',
  },
  content: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    backgroundSize: '30px 30px',
    backgroundImage: `
        linear-gradient(to right, black 1px, transparent 1px),
        linear-gradient(to bottom, black 1px, transparent 1px)`,
    border: '1px solid black',
    borderRadius: '18px',
  },
  gridContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  bannerContainer: {
    marginTop: '10%',
    marginLeft: 'auto',
    height: '70%',
    width: '90%',
    [BP5]: {
      margin: 'auto',
      height: '90%',
    },
  },
  btn: {
    fontSize: '18px',
    alignSelf: 'start',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '10%',
  },
  btn2: {
    width: '80%',
    mb: 2,
  },
  connectBtn: {
    width: '100%',
    minHeight: '65px',
    mb: 2,
    fontSize: '20px',
    color: '#fff',
    border: '2px solid #FFE500',
    background: '#000',
    margin: '0px',
    '&:hover': {
      background: '#000',
    },
  },
  buttonsContainer: {
    marginTop: '30%',
    textAlign: 'center',
  },
  btnDiscordIcon: {
    height: '22px',
    marginTop: '-2px',
    pl: 1,
  },
  btnTwitterIcon: {
    height: '18px',
    marginTop: '-2px',
    pl: 1,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [BP5]: {
      display: 'none',
    },
  },
  btnsContainer: {
    display: 'flex',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '10%',
    gap: '30px',
    [BP2]: {
      flexDirection: 'column',
    },
    [BP3]: {
      margin: 'auto',
      // width: "60%",
      marginBottom: '20px',
    },
    [BP5]: {
      marginLeft: '5%',
    },
  },
  discordBtn: {
    fontSize: '18px',
    alignSelf: 'start',
  },
  mintBtn: {
    flexGrow: 1,
    fontSize: '18px',
    // background: "#fff",
    marginRight: '20px',
  },
  iconBtn: {
    background: '#000',
    margin: '5px',
    width: '100%',
    '&:hover': {
      background: '#000',
    },
  },
  iconBtnContainer: {
    display: 'inline-flex',
    justifyContent: 'center',
    minWidth: '80%',
  },
};

const Popups = {
  CONNECT_WALLET_MINT: {
    text: 'You need to connect your wallet to mint an NFT.',
  },
  ALREADY_MINTED_ALLOW_LIST: {
    text: 'Your wallet address has already minted The Association NFT.\n\nOnly 1 NFT can be minted per wallet during the Allow List mint.',
    popupStyle: STYLE.YELLOW,
  },
  CONNECT_WALLET_CHECK_PLACEMENT: {
    text: 'You need to connect your wallet to check placement on Allow List.',
  },
  ON_ALLOW_LIST: {
    text: 'Your wallet address is on the Allow List.',
    text2:
      'Allow List mint opens on April 20th.\nWallets on the Allow List can mint 1 NFT during the Allow List Mint.',
    popupStyle: STYLE.BLUE,
  },
  NOT_ON_ALLOW_LIST: {
    text: `Your wallet address is not on the Allow List.\n\n`,
    popupStyle: STYLE.RED,
  },
  JOIN_DISCORD: {
    text2:
      'To mint The Association NFT, you need to be on the allow list. Spots on the allow list were made available to the earliest members of NBA Discord. \n\n To confirm your spot on the allow list, connect your wallet and click Check Placement on the Allow List button.',
    btnText: (
      <>
        JOIN DISCORD{' '}
        <Box sx={sx.btnIcon} component="img" src={discordIcon}></Box>
      </>
    ),
  },
  NFT_REVEAL: {
    text2:
      'The NFTs are being issued in a blind-mint, meaning that you will not know which 2022 NBA Playoffs player you will receive prior to minting.\n\nPlayers will be assigned randomly and transparently using Chainlink VRF on April 22, 2022.\n\nEveryone will have a fair and equal chance of minting any player from any of the 16 Playoff teams.',
    btnText: (
      <>
        JOIN DISCORD{' '}
        <Box sx={sx.btnIcon} component="img" src={discordIcon}></Box>
      </>
    ),
  },
};

const Hero = () => {
  const { address } = useWeb3Ctx();
  const [showPopup, setShowPopup] = useState(null);
  const history = useHistory();
  const whitelistSignature = useAllowList();
  const saleInfo = useSaleInfo(60000);
  const {
    saleStatus,
    timeStart,
    mintAvailableSale,
    mintAvailableWhitelist,
    maxMintSale,
    maxMintWhitelist,
    fetchInfo,
  } = saleInfo;
  const { finish, timeLeft } = useCountdown(timeStart);

  useEffect(() => {
    if (finish) {
      fetchInfo();
    }
  }, [finish]);

  const goOpensea = () => {
    window.open('https://opensea.io/collection/the-association-nft', 'blank');
  };

  const goDiscord = () => {
    window.open('https://discord.gg/nba', 'blank');
  };

  const goTwitter = () => {
    window.open('https://twitter.com/nbaxnft', 'blank');
  };

  const allowListPopup = () => {
    setShowPopup({
      ...Popups.JOIN_DISCORD,
      btnOnClick: goDiscord,
    });
  };

  const nftRevealPopup = () => {
    setShowPopup({ ...Popups.NFT_REVEAL, btnOnClick: goDiscord });
  };

  const goCheckout = () => {
    if (!address) {
      setShowPopup(Popups.CONNECT_WALLET_MINT);
      return;
    } else if (!whitelistSignature) {
      setShowPopup(Popups.NOT_ON_ALLOW_LIST);
      return;
    } else if (saleStatus == SALE_STATUS.SALE && mintAvailableSale < 1) {
      setShowPopup({
        text: `Your wallet address has already minted ${maxMintSale} The Association NFTs.\n\nOnly ${maxMintSale} NFTs can be minted per Wallet.`,
        popupStyle: STYLE.YELLOW,
      });
      return;
    } else if (
      saleStatus == SALE_STATUS.PRESALE &&
      mintAvailableWhitelist < 1
    ) {
      setShowPopup(Popups.ALREADY_MINTED_ALLOW_LIST);
      return;
    }
    history.push('/checkout');
  };

  const checkPlacement = () => {
    if (!address) {
      setShowPopup(Popups.CONNECT_WALLET_CHECK_PLACEMENT);
    } else if (!whitelistSignature) {
      setShowPopup(Popups.NOT_ON_ALLOW_LIST);
    } else {
      setShowPopup(Popups.ON_ALLOW_LIST);
    }
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.container}>
        <Box sx={sx.content}>
          <Box sx={sx.animationLayer2}>
            <Box component="img" src={heroAnimation2}></Box>
          </Box>
          <Box sx={sx.animationLayer1}>
            <Box component="img" src={heroAnimation1}></Box>
          </Box>
          <Grid container sx={sx.gridContainer}>
            <Grid item sx={sx.col} xs={12} lg={9}>
              <Box sx={sx.bannerContainer}>
                <Banner
                  {...saleInfo}
                  countdown={timeLeft}
                  allowListPopup={allowListPopup}
                  nftRevealPopup={nftRevealPopup}
                />
              </Box>

              <Box sx={sx.btnsContainer}>
                <Button
                  sx={{ ...sx.discordBtn, display: { xs: 'flex', lg: 'none' } }}
                  variant="contained2"
                  onClick={() => goDiscord()}
                >
                  JOIN DISCORD{' '}
                  <Box
                    sx={sx.btnDiscordIcon}
                    component="img"
                    src={discordIcon}
                  ></Box>
                </Button>
                <Button
                  sx={{ ...sx.discordBtn, display: { xs: 'flex', lg: 'none' } }}
                  variant="contained2"
                  onClick={() => goTwitter()}
                >
                  FOLLOW TWITTER{' '}
                  <Box
                    sx={sx.btnTwitterIcon}
                    component="img"
                    src={twitterIcon}
                  ></Box>
                </Button>
                {saleStatus == SALE_STATUS.UPCOMING_PRESALE && (
                  <Button
                    sx={sx.mintBtn}
                    variant="contained2"
                    onClick={() => checkPlacement()}
                  >
                    CHECK PLACEMENT ON ALLOW LIST
                  </Button>
                )}

                {saleStatus == SALE_STATUS.PRESALE && (
                  <Button
                    sx={sx.mintBtn}
                    variant="contained2"
                    // onClick={() => goCheckout()}
                  >
                    {/* ALLOW LIST MINT */}
                    MINT PAUSED
                  </Button>
                )}

                {saleStatus == SALE_STATUS.SALE && (
                  <Button
                    sx={sx.mintBtn}
                    variant="contained2"
                    // onClick={() => goCheckout()}
                  >
                    MINT PAUSED
                  </Button>
                )}

                {/* {saleStatus == SALE_STATUS.SALE_ENDED && (
                  <Button
                    sx={sx.mintBtn}
                    variant="contained2"
                    onClick={() => goOpensea()}
                  >
                    SECONDARY MARKET{" "}
                    <Box
                      sx={sx.btnIcon}
                      component="img"
                      src={openseaIcon}
                    ></Box>
                  </Button>
                )} */}

                <Button
                  sx={sx.mintBtn}
                  variant="contained2"
                  onClick={() => goOpensea()}
                >
                  SECONDARY MARKET{' '}
                  <Box sx={sx.btnIcon} component="img" src={openseaIcon}></Box>
                </Button>
              </Box>
            </Grid>
            <Grid item sx={sx.linkContainer} xs={12} lg={3}>
              <Box sx={sx.buttonsContainer}>
                <ConnectWalletBtn sxStyle={sx.connectBtn} variant="contained2">
                  CONNECT WALLET
                </ConnectWalletBtn>
                <Button
                  sx={sx.btn2}
                  variant="contained2"
                  onClick={() => navigate(history, '/#about')}
                >
                  About
                </Button>
                <Button
                  sx={sx.btn2}
                  variant="contained2"
                  onClick={() => navigate(history, '/#dynamic-nft')}
                >
                  DYNAMIC NFTS
                </Button>
                <Button
                  sx={sx.btn2}
                  variant="contained2"
                  onClick={() => navigate(history, '/explorer')}
                >
                  EXPLORER
                </Button>
                <Button
                  sx={sx.btn2}
                  variant="contained2"
                  onClick={() => navigate(history, '/#faq')}
                >
                  FAQ
                </Button>
                <Button
                  sx={sx.btn2}
                  variant="contained2"
                  onClick={() => navigate(history, '/wallet')}
                >
                  WALLET
                </Button>

                <Box
                  sx={{
                    ...sx.iconBtnContainer,
                    display: { xs: 'none', md: 'inline-flex' },
                  }}
                >
                  <Button
                    sx={sx.iconBtn}
                    variant="contained2"
                    onClick={() => goTwitter()}
                  >
                    <Box
                      sx={{ ...sx.btnTwitterIcon, paddingLeft: '0px' }}
                      component="img"
                      src={twitterIcon2}
                    ></Box>
                  </Button>
                  <Button
                    sx={sx.iconBtn}
                    variant="contained2"
                    onClick={() => goDiscord()}
                  >
                    <Box
                      sx={{ ...sx.btnDiscordIcon, paddingLeft: '0px' }}
                      component="img"
                      src={discordIcon2}
                    ></Box>
                  </Button>
                  {saleStatus > 0 && (
                    <Button
                      sx={sx.iconBtn}
                      variant="contained2"
                      onClick={() => goOpensea()}
                    >
                      <Box
                        sx={sx.btnIcon}
                        component="img"
                        src={openseaIcon2}
                      ></Box>
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <HeroBorderTexts />

      <HeroPopup
        closePopup={() => setShowPopup(null)}
        showPopup={showPopup != null}
        {...showPopup}
      />
    </Box>
  );
};
export default Hero;
