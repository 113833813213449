import PaperBox from "../../common/PaperBox";
import InfoPanel from "../../common/InfoPanel";
import CalendarItem from "../../common/CalendarItem";
import { Box, Grid, Typography } from "@mui/material";
import calendarBg1 from "../../../assets/images/s14.jpg"
import calendarBg2 from "../../../assets/images/s4.jpg"
import calendarBg3 from "../../../assets/images/s3.jpg"

const BP1 = "@media (max-width: 899px)";
const BP2 = "@media (max-width: 1024px)";
const BP3 = "@media (max-width: 420px)";

const sx = {
  root: {
    position: "relative",
    background: "#000",
    padding: "2%",
  },
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
    padding: "2%",
    background: "#102F5E",
    backgroundSize: "30px 30px",
    backgroundImage: `
        linear-gradient(to right, black 1px, transparent 1px),
        linear-gradient(to bottom, black 1px, transparent 1px)`,
    border: "1px solid black",
  },
  gridContainer: {
    height: "100%",
  },
  row: {
    height: "100%",
  },
  header: {
    fontSize: "66px",
    lineHeight: "80px",
    color: "#fff",
    [BP2]: {
      fontSize: "54px",
      lineHeight: "66px",
    },
    [BP3]: {
      fontSize: "44px",
      lineHeight: "56px",
    }
  },
  infoPanelContainer: {
    background: "#DA1A32",
    p: 3,
    my: 3,
  },
  headerContainer: {
    alignSelf: "center",
    [BP1]: {
      margin: "32px 16px",
    },
  },
  infoPanelItem: {
    [BP1]: {
      flexBasis: "100%",
      maxWidth: "100%",
    },
  }

};

const texts1 = ["Allow List Mint Begins"];
const texts2 = ["The Association NFT Reveal"];

const About = () => {
  return (
    <Box sx={sx.root} id={"about"}>
      <Box sx={sx.container}>
        <Grid container>
          <Grid item sm={12} md={6} sx={sx.headerContainer}>
            <Typography variant="heading1" sx={sx.header}>
              ABOUT THE COLLECTION
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <PaperBox>
              <Typography>
                The Association is a collection of dynamic NFTs that change in appearance over time based on team and player on-court performance.
                <br></br> <br></br>
                30,000 NFTs are being minted during the 2022 NBA Playoffs (125 editions per player).
                <br></br> <br></br>
                The Association NFTs are being issued in a blind-mint, meaning that nobody will know which 2022 NBA Playoffs player they will receive prior to the reveal.
                <br></br> <br></br>
                Players will be assigned randomly and transparently using Chainlink VRF so everyone will have a fair and equal chance of minting any player from any of the 16 Playoff teams.
              </Typography>
            </PaperBox>
          </Grid>
        </Grid>

        <Grid container sx={sx.infoPanelContainer} columns={16}>
          <Grid item xs={12} md={4} sx={sx.infoPanelItem}>
            <InfoPanel number={16} text={"TEAMS"} color={"yellow"} />
          </Grid>
          <Grid item xs={12} md={4} sx={sx.infoPanelItem}>
            <InfoPanel number={240} text={"PLAYERS"} color={"blue"} />
          </Grid>
          <Grid item xs={12} md={4} sx={sx.infoPanelItem}>
            <InfoPanel number={"125"} text={"EDITION COUNT PER PLAYER"} color={"red"} />
          </Grid>
          <Grid item xs={12} md={4} sx={sx.infoPanelItem}>
            <InfoPanel
              number={"30,000"}
              text={"TOTAL SUPPLY"}
              color={"yellow"}
            />
          </Grid>
          {/* <Grid item xs={12} md={3} sx={sx.infoPanelItem}>
            <InfoPanel number={"FREE"} text={""} color={"blue"} />
          </Grid> */}
        </Grid>

        <CalendarItem month={"APRIL"} day={20} texts={texts1} background={calendarBg1} />
      </Box>
    </Box>
  );
};

export default About;
