import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Checkbox } from "@mui/material";
import NumericInput from "./NumericInput";

const BP3 = "@media (max-width: 384px)";

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "458px",
    width: "100%",
    margin: "auto",
    mb: 2,
  },
  title: {
    color: "#000",
    textAlign: "center",
    fontSize: 22,
    fontWeight: 700,
    mb: 3,
  },
  text: {
    fontFamily: "poppins",
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: 14,
    alignSelf: "center",
  },
  span: {
    fontFamily: "poppins",
    textTransform: "none",
    fontWeight: 500,
    fontSize: 11,
  },
  container: {
    mx: "auto",
    width: "80%",
    borderRadius: "10px",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.1)",
    py: 2,
  },
  row: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    minHeight: "30px",
    borderColor: "rgba(0,0,0,0.2)",
    alignSelf: "center",
    justifyContent: "center",
    // gap: "30px",
    mb: 1,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    minWidth: "50%",
    alignItems: "center",
  },
  img: {
    height: "12px",
    alignSelf: "center",
    marginRight: "3px",
    marginBottom: "2px",
  },
  mintBtn: {
    mt: 3,
    minHeight: "55px",
    width: "100px",
    borderRadius: "5px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    backgroundColor: "unset",
    border: "2px solid #FF692B",
    color: "#FF692B",
    marginTop: "15px",
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      border: "2px solid #FF692B",
      backgroundColor: "#FF692B",
      color: "#ffffff",
    },
    "&:disabled": {
      border: "2px solid rgba(0, 0, 0, 0.26);",
    },
    [BP3]: {
      width: "100%",
      px: "14px",
    },
  },
  priceContainer: {
    display: "flex",
    alignSelf: "center",
    my: 0,
  },
  price: {
    pt: 1,
    fontFamily: "poppins",
    fontWeight: "500",
    fontSize: 24,
  },
  ethLabel: {
    fontFamily: "poppins",
    fontWeight: "500",
    fontSize: 18,
  },
  amount: {
    my: 0,
    pt: 1,
    fontFamily: "poppins",
    fontWeight: "500",
    fontSize: 24,
  },
  checkboxContainer: {
    fontFamily: "bau",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FF692B",
    mt: 1,
    py: 2,
  },
  checkbox: {
    margin: "0",
    color: "#FF692B",
    "&.Mui-checked": {
      color: "#FF692B",
    },
    padding: "0",
    fontSize: "24px",
    mr: 1,
    mb: "3px",
  },
  termsLink:{
    textDecoration: "underline",
    cursor: "pointer",
    display: "inline-block",
  }
};

const MintQuantity = ({
  title,
  price,
  maxAmount,
  onClickMint,
  editable,
  mintAmount,
  setShowTermsAndConditions,
}) => {
  const [quantity, setQuantity] = useState(maxAmount);
  const [totalPrice, setTotalPrice] = useState(0);
  const [checked, setChecked] = useState(false);

  // console.log("MINTQUANTYTY", typeof price);
  useEffect(() => {
    setTotalPrice(parseFloat((price * quantity).toFixed(3)));
  }, [quantity]);

  const onNumberInput = (val) => {
    setQuantity(val);
  };

  const mint = (quantity, totalPrice) => {
    if (!checked) {
      return;
    }

    onClickMint(quantity, totalPrice);
  };

  return (
    <Box sx={sx.root}>
      <Typography sx={sx.title}>{title}</Typography>

      <Box sx={sx.container}>
        <Box sx={sx.row}>
          <Box sx={sx.col}>
            {editable ? (
              <>
                <Typography sx={sx.text} variant="text">
                  Quantity
                </Typography>
                <NumericInput
                  value={mintAmount || 1}
                  max={maxAmount}
                  min={0}
                  onChange={onNumberInput}
                />
                <Typography sx={sx.span} variant="text">
                  ({maxAmount} max)
                </Typography>
              </>
            ) : (
              <>
                <Typography sx={sx.text} variant="text">
                  Quantity
                </Typography>
                <Typography sx={sx.amount} variant="text">
                  {maxAmount}
                </Typography>
              </>
            )}
          </Box>

          <Box sx={sx.col}>
            <Typography sx={sx.text} variant="text">
              Price
            </Typography>
            <Box display="flex" sx={sx.priceContainer}>
              <Typography sx={sx.price} variant="text">
                <Typography component="span" sx={sx.ethLabel}>
                  ETH
                </Typography>{" "}
                {totalPrice}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: "14px", fontFamily: "bau" }}>
            * Price does not include gas fees
          </Typography>
        </Box>
      </Box>

      <Box sx={sx.checkboxContainer}>
        <Checkbox
          checked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
          sx={sx.checkbox}
        ></Checkbox>
        I agree to the <Box sx={sx.termsLink} onClick={setShowTermsAndConditions}>Terms & Conditions</Box>
      </Box>
      <Button
        variant="banner"
        sx={sx.mintBtn}
        onClick={() => mint(quantity, totalPrice)}
        disabled={quantity === 0 || quantity > maxAmount || !checked}
      >
        Mint
      </Button>
    </Box>
  );
};

/* eslint-disable react/forbid-prop-types */
MintQuantity.propTypes = {
  title: PropTypes.string,
  maxAmount: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  onClickMint: PropTypes.any.isRequired,
};

export default MintQuantity;
