import { useEffect, useState } from 'react';

import useInterval from './useInterval';

export const useCountdown = (time) => {

    const [startTime, setStartTime] = useState(0);
    const [timeLeft, setTimeLeft] = useState(
        {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        });

    const [finish, setFinish] = useState(false);


    useEffect(() => {
        setStartTime(time);
        if (time > 0) {
            setFinish(false);
        } else {
            // setFinish(true);
        }
    }, [time])


    useInterval(() => {
        if (!finish) {
            tick();
        }
    }, 1000);

    const tick = () => {

        let tLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        }

        if (startTime > 0) {

            let now = Date.parse(new Date()) / 1000;
            let diff = Math.floor(startTime) - now;

            if (diff > 0) {
                if (diff > 86400) {
                    tLeft.days = Math.floor(diff / 86400);
                    diff -= tLeft.days * 86400;
                }
                if (diff > 3600) {
                    tLeft.hours = Math.floor(diff / 3600);
                    diff -= tLeft.hours * 3600;
                }
                if (diff > 60) {
                    tLeft.minutes = Math.floor(diff / 60);
                    diff -= tLeft.minutes * 60;
                }
                tLeft.seconds = diff;
            } else setFinish(true)
        }
        setTimeLeft(tLeft);
    }

    return {
        finish, timeLeft: {
            ...timeLeft,
            days: timeLeft.days > 0 ? String(timeLeft.days).padStart(2, "0") : "00",
            hours: String(timeLeft.hours).padStart(2, "0"),
            minutes: String(timeLeft.minutes).padStart(2, "0"),
            seconds: String(timeLeft.seconds).padStart(2, "0")
        }
    };
}