import React, { useEffect, useState } from "react";
import useWeb3Ctx from "../../hooks/useWeb3Ctx";
import {
  useZoomContract,
  useTheAssociationTokenContract,
  useTheAssociationTokenV2Contract,
} from "../../hooks/useContract";
import { useMoralisGetNftsAssociation2 } from "../../hooks/useMoralis";

import {
  zoomFetchTokenUris,
  zoomFetchTokenUrisWithTokenIds,
} from "../../utils/zoom2";

import FullscreenModal from "../../components/common/FullscreenModal";
import Card from "../common/Card.jsx";
import Header from "../Home/components/Header";
import Footer from "../Home/components/Footer";
import background from "../../assets/images/termsBackground.png";

import { Box, Typography, Grid, Button } from "@mui/material";
import { SpinnerRoundFilled as Spinner } from "spinners-react";

const sx = {
  root: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 70px)",
    position: "relative",
    transition: "all .3s",
    background: `url(${background})`,
    backgroundColor: "#17408b",
    backgroundBlendMode: "multiply",
    pt: "140px",
    pb: "60px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "0 25px",
    mb: 4,
  },
  header: {
    textAlign: "center",
    color: "#fff",
    fontSize: "80px",
    mt: 5,
  },
  text: {
    color: "#fff",
    fontSize: "30px",
    textAlign: "center",
    my: 5,
  },
  address: {
    color: "#fff",
    fontSize: "30px",
    textAlign: "center",
    my: 5,
    textDecoration: "underline",
  },
  gridContainer: {
    mx: "auto",
    maxWidth: "80%",
    justifyContent: "center",
  },
  btn: {
    mx: "auto",
    my: 4,
    fontSize: "20px",
    minHeight: "65px",
    backgroundColor: "#000",
    color: "#fff",
    py: "5px",
    px: "15px",
    border: "2px solid #FFE500",
    "&:hover": {
      background: "#000",
    },
  },

  imgContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "1073px",
    margin: "auto",
  },
};

const Wallet = () => {
  const { address, handleConnect } = useWeb3Ctx();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fullscreenSrc, setFullscreenSrc] = useState(null);
  const [metadata, setMetadata] = useState([]);
  const [metadata2, setMetadata2] = useState([]);
  const [allMetadata, setAllMetadata] = useState([]);

  const associationContract = useTheAssociationTokenContract();
  const token2Contract = useTheAssociationTokenV2Contract();
  const zoomContract = useZoomContract();

  const {
    fetch,
    data,
    isLoading: moralisIsLoading,
  } = useMoralisGetNftsAssociation2();

  useEffect(() => {
    if (address) {
      getTokens();
      fetch();
    } else {
      setMetadata([]);
      setMetadata2([]);
    }
  }, [address]);

  useEffect(() => {
    let allMetadata = [];
    if (metadata) {
      allMetadata = allMetadata.concat(metadata);
    }
    if (metadata2) {
      allMetadata = allMetadata.concat(metadata2);
    }
    if (allMetadata.length > 0) {
      setAllMetadata(allMetadata);
    } else {
      setAllMetadata([]);
    }
    console.log(allMetadata);
  }, [metadata, metadata2]);

  useEffect(() => {
    if (data?.result) {
      const tokenIds = data.result.map((e) => {
        return parseInt(e.token_id);
      });

      if (tokenIds.length > 0) {
        (async () => {
          const metadata = await zoomFetchTokenUrisWithTokenIds(
            token2Contract,
            zoomContract,
            tokenIds
          );
          setMetadata2(metadata);
        })();
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isOpen) {
      setFullscreenSrc(null);
    }
  }, [isOpen]);

  const getTokens = async () => {
    setIsLoading(true);

    const tokens = await zoomFetchTokenUris(
      associationContract,
      zoomContract,
      address
    );

    if (tokens) {
      setMetadata(tokens);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setFullscreenSrc(null);
    }
  }, [isOpen]);

  const setFullscreenSource = (src) => {
    setFullscreenSrc(src);
    setOpen(true);
  };

  return (
    <>
      <Header />

      <Box sx={sx.root}>
        <Box sx={sx.content}>
          <Typography variant="heading1" sx={sx.header}>
            WALLET
          </Typography>

          {!address && (
            <Typography variant="heading1" sx={sx.text}>
              Please connect your wallet.
            </Typography>
          )}
          {!address && (
            <>
              <Button variant="contained2" onClick={handleConnect} sx={sx.btn}>
                Connect Wallet
              </Button>
            </>
          )}
        </Box>

        {address && (
          <Typography variant="heading1" sx={sx.address}>
            {address.substr(0, 6) +
              "..." +
              address.substr(address.length - 4, 4)}
          </Typography>
        )}

        {(isLoading || moralisIsLoading) && (
          <Box sx={{ textAlign: "center", mb: 5 }}>
            <Spinner color="white" />
          </Box>
        )}

        {address && (
          <>
            {!isLoading && !moralisIsLoading && allMetadata.length == 0 && (
              <Typography variant="heading1" sx={sx.text}>
                Your wallet is empty.
              </Typography>
            )}
            <Grid
              container
              sx={sx.gridContainer}
              columnSpacing={"14px"}
              rowSpacing={"14px"}
            >
              {allMetadata &&
                allMetadata.map((metadata) => {
                  if (metadata) {
                    return (
                      <Card
                        image={metadata.image}
                        name={metadata.series || ""}
                        id={metadata.id || metadata.tokenId}
                        setFullscreenSource={setFullscreenSource}
                        md={3}
                        sx={12}
                        titleFontSize={35}
                        key={metadata.id || metadata.tokenId}
                      />
                    );
                  }
                })}
            </Grid>
          </>
        )}

        <FullscreenModal
          setOpen={setOpen}
          isOpen={isOpen}
          imgSrc={fullscreenSrc}
        />
      </Box>
      <Footer />
    </>
  );
};

export default Wallet;
