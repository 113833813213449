export function navigate(history, path) {
  const { hash } = history.location;
  const hashPath = hash.substr(1);
  if (path.substr(1) == "" || hash != path.substr(1)) {
    history.push(path);
  } else if (hash && document.getElementById(hashPath)) {
    var topOfElement = document.getElementById(hashPath)?.offsetTop;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  }
}

export default navigate;