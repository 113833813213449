import React from "react";

import placeholder from "../../assets/images/nft/preview.png";

import { Box, Grid } from "@mui/material";

const BP3 = "@media (max-width: 996px)";
const BP5 = "@media (max-width: 899px)";
const BP6 = "@media (max-width: 671px)";
const BP7 = "@media (max-width: 599px)";
const BP8 = "@media (max-width: 594px)";
const BP9 = "@media (max-width: 491px)";
const BP10 = "@media (max-width: 386px)";

const sx = {
  root: {
    position: "relative",
    width: "100%",
    alignSelf: "center",
    m: "auto",
  },
  nftContainer: {
    backgroundColor: "#fff",
    width: "100%",
    paddingTop: "133.67%",
    position: "relative",
    overflow: "hidden",
    transition: "all .3s",
    perspective: "1px",
    zIndex: 1,
    border: "3px solid black"
  },
  nftImg: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  text: {
    mt: "15px",
    fontFamily: "amallia",
    fontSize: "50px",
    color: "#3F4B0C",
    lineHeight: "75px",
    textAlign: "center",
    [BP3]: {
      fontSize: "45px",
    },
    [BP5]: {
      fontSize: "50px",
    },
    [BP6]: {
      fontSize: "45px",
    },
    [BP7]: {
      fontSize: "50px",
    },
    [BP8]: {
      fontSize: "40px",
    },
    [BP9]: {
      fontSize: "30px",
    },
    [BP10]: {
      fontSize: "23px",
    },
  },
  id: {
    fontFamily: "boucherie-block",
    fontSize: "22px",
    color: "#C58200",
    lineHeight: "66px",
    textAlign: "center",
  },
  background: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "20px",
    right: "-20px",
    zIndex: 0,
  },
  idContainer: {
    minHeight: "45px",
    width: "100%",
    mt: 4,
    border: "3px solid #000",
    background: "#FFE500",
    fontFamily: "abc-grav",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};
const zeroPad = (num, places) => String(num).padStart(places, "0");

const Card = ({
  setFullscreenSource,
  image,
  name,
  id,
  md,
  xs,
  sm,
  rootStyle,
  titleFontSize,
  background,
}) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} sx={{ ...sx.root, ...rootStyle }}>
      <Box
        sx={{
          ...sx.nftContainer,
          ...(setFullscreenSource
            ? { cursor: "pointer" }
            : { cursor: "unset" }),
        }}
        onClick={() => {
          if (setFullscreenSource) {
            setFullscreenSource(image || placeholder);
          }
        }}
      >
        <Box component="img" sx={sx.nftImg} src={image || placeholder} />
      </Box>
      {background && (
        <Box sx={{ ...sx.background, background: background }}></Box>
      )}
      {id && <Box sx={sx.idContainer}>TOKEN ID : {id}</Box>}
    </Grid>
  );
};

export default Card;
