import { useEffect, useState } from "react";
import useWeb3Ctx from "../../hooks/useWeb3Ctx";
import { ECAddress } from "ec-commons";
import { Box, Button, Typography } from "@mui/material";
const BP1 = "@media (max-width: 999px)";
const BP5 = "@media (max-width: 500px)";

const sx = {
  root: {
    display: "inline-flex",
    alignItems: "center",
    minWidth: "80%",
    [BP1]: {
      pt: "0",
    },
  },
  connectBtn: {
    fontSize: "20px",
    borderRadius: "0px",
    minHeight: "65px",
    width: "130px",
    color: "#fff",
    margin: "auto",
    border: "2px solid #FFE500",
    background: "#000",
    whiteSpace: "nowrap",
    width: "80%",
    "&:hover": {
      background: "#000",
    },
  },
  buttonText: {
    width: "100%",
    height: "100%",
    backgroundColor: "#030200",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    fontFamily: "Boucherie-Block",
  },
  disconnectBtn: {
    textAlign: "center",
    cursor: "pointer",
    color: "#FFF",
    transition: "color 0.3s ease-in-out",
    fontWeight: "500",
    "&:hover": {
      color: "#9D4C10",
    },
  },
  address: {
    color: "#020100",
    zIndex: 1,
    minWidth: "150px",
    ml: "auto",
    mr: "auto",
    textTransform: "uppercase",
    [BP5]: {
      transform: "scale(0.9)",
    },
  },
  errorBtn: {
    textTransform: "uppercase",
    lineHeight: "1.75",
    color: "#020100",
    fontSize: "0.8rem",
    fontWeight: "500",
    padding: "5px 15px",
    minWidth: "150px",
    backgroundColor: "#FF1414",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .3s",
    textTransform: "unset",
    borderRadius: "8px",
    ml: "50px",
    "&:hover": {
      backgroundColor: "#FF1414",
      opacity: "0.8",
    },
  },
};

const ConnectWalletBtn = ({ sxStyle, variant, children }) => {
  const [showDisconnect, setShowDisconnect] = useState(false);

  const {
    handleConnect,
    handleDisconnect,
    isCorrectNetwork,
    address,
    walletCheck,
  } = useWeb3Ctx();

  useEffect(() => {
    if (address) {
      setShowDisconnect(false);
    }
  }, [address]);

  return (
    <>
      <Box sx={sx.root}>
        {!address && (
          <Button
            variant={variant ? variant : "outlined"}
            sx={{ ...sx.connectBtn, ...sxStyle }}
            onClick={handleConnect}
          >
            {children}
          </Button>
        )}
        {address && isCorrectNetwork && (
          <Button
            sx={{ ...sx.connectBtn, ...sxStyle }}
            variant={variant ? variant : "outlined"}
            onClick={() => handleDisconnect()}
            onMouseOver={() => setShowDisconnect(true)}
            onMouseLeave={() => setShowDisconnect(false)}
          >
            {!showDisconnect ? (
              <>
                {address.substr(0, 6) +
                  "..." +
                  address.substr(address.length - 4, 4)}
              </>
            ) : (
              "DISCONNECT"
            )}
          </Button>
        )}
        {address && !isCorrectNetwork && (
          <Button
            sx={{ ...sx.connectBtn, ...sxStyle, background: "#FF1414" }}
            variant={variant ? variant : "outlined"}
            onClick={walletCheck}
          >
            Wrong Network
          </Button>
        )}
      </Box>
    </>
  );
};

export default ConnectWalletBtn;
