
import { useEffect, useState, useRef } from 'react';
import ResizeObserver from "resize-observer-polyfill";

const useContainerDimensions = myRef => {

  const getDimensions = () => ({
    width: myRef.current && myRef.current.offsetWidth,
    height: myRef.current && myRef.current.offsetHeight
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        // const { width, height } = entry.contentRect;
        setDimensions(getDimensions());
      });
    });

    if (myRef.current) {
      resizeObserverRef.current.observe(myRef.current);
    }

    // window.addEventListener("resize", handleResize)

    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    }
  }, [myRef, myRef.current])
  return dimensions;
}

export default useContainerDimensions;