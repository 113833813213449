import Home from './components/Home/Home';
import Wallet from './components/Wallet/Wallet';
import RegionLocked from './components/RegionLocked/RegionLocked';
import MintPage from './components/MintPage/MintPage';
import NFTTermsAndConditions from './components/NFTTermsAndConditions/NFTTermsAndConditions'
import Explorer from './components/Explorer/Explorer';

const routes = [
	{
		path: '/',
		component: Home,
		exact: true,
	},
	{
		path: '/wallet',
		component: Wallet,
		exact: true,
	},
	{
		path: '/region-locked',
		component: RegionLocked,
		exact: true,
	},
	{
		path: '/checkout',
		component: MintPage,
		exact: true,
	},
	{
		path: '/nft-terms-and-conditions',
		component: NFTTermsAndConditions,
		exact: true,
	},
	{
		path: '/explorer',
		component: Explorer,
		exact: true,
	}
];

export default routes;
