import CheckoutCard from "./CheckoutCard";
import { Box, Typography } from "@mui/material";
import "../../../components/common/CounterCircle/CounterCircle.css";

const sx = {
  root: {
    width: "100%",
    height: "100%",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
  },
  title: {
    textAlign: "center",
    color: "#FF692B",
    lineHeight: "30px",
  },
  url: {
    textDecoration: "none",
    color: "#FF692B",
    fontWeight: "700",
  },
};

const TxProgressCard = ({ isOpen, setOpen, txEtherScan }) => {
  return (
    <CheckoutCard
      header={
        <>
          Transaction
          <br /> In Progress
        </>
      }
      isTxPopup={true}
      isOpen={isOpen}
    >
      <Typography variant="text" sx={{ mb: 4 }}>
        Please wait while your transaction is being processed. <br />
        {txEtherScan && (
          <>
            You can check the transaction status on{" "}
            <Box
              component="a"
              href={txEtherScan}
              sx={sx.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Etherscan
            </Box>
            .
          </>
        )}
      </Typography>

      <>
        <div
          className="counter-circle"
          style={{ margin: "0 auto", marginBottom: "10px" }}
        >
          <div className="counter-rotary-holder" id="txRotator">
            <div className="counter-rotary-item" />
          </div>
          <div className="counter-shadow">
            <div className="counter-content-holder">
              <div className="counter-text">LOADING</div>
            </div>
          </div>
        </div>
      </>
    </CheckoutCard>
  );
};

export default TxProgressCard;
