import { useMoralisWeb3Api, useMoralisWeb3ApiCall, useMoralisCloudFunction } from "react-moralis";
import { useMemo } from 'react'
import useWeb3Ctx from "./useWeb3Ctx";
import {
    THE_ASSOCIATION_TOKENV2_ADDRESSES
} from '../abi/constants/addresses'

// // returns null on errors
// export function useMoralisGetNfts(addressOrAddressMap) {
//     const { address: account, chainId, isCorrectNetwork, defaultChainId } = useWeb3Ctx();
//     const Web3Api = useMoralisWeb3Api();

//     return useMemo(() => {
//         let address;
//         if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
//         else address = addressOrAddressMap[isCorrectNetwork ? chainId : defaultChainId]
//         if (!address) return null

//         return useMoralisWeb3ApiCall(
//             Web3Api.account.getNFTs,
//             {
//                 chain: chainId,
//                 format: "decimal",
//                 token_addresses: [address],
//                 address: account,
//             }
//         );
//     }, [addressOrAddressMap, chainId, account, isCorrectNetwork])
// }

const getMoralisChainId = (chainId) => {
    if (chainId == 1) {
        return "eth"
    } else if (chainId == 4) {
        return "rinkeby"
    }
}

export function useMoralisGetNftsAssociation2() {
    const addressOrAddressMap = THE_ASSOCIATION_TOKENV2_ADDRESSES
    const { address: account, chainId, isCorrectNetwork, defaultChainId } = useWeb3Ctx();
    // const Web3Api = useMoralisWeb3Api();
    let address;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[isCorrectNetwork ? chainId : defaultChainId]
    if (!address) return null

    return useMoralisCloudFunction(
        "getNftUsers",
        {
            chainId: getMoralisChainId(chainId || defaultChainId),
            // tokenAddresses: address,
            ownerAddress: account,
        },
        { autoFetch: false }
    );
}