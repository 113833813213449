import React from "react";

import Hero from "./components/Hero";
import About from "./components/About";
import DynamicNft from "./components/DynamicNft";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import Header from "./components/Header";
// import Footer from "../common/Footer";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <DynamicNft />
      <Faq />
      <Footer />
    </>
  );
};

export default Home;
