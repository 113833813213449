import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import config from "../../../config";
import useWeb3Ctx from "../../../hooks/useWeb3Ctx";
import useAllowList from "../../../hooks/useAllowList";
import { SALE_STATUS } from "../../../hooks/useSaleInfo";
import { useTheAssociationSalesContract } from "../../../hooks/useContract";

import Card from "../../common/Card";
import BackdropModal from "../../common/Modal/BackdropModal";
import MintQuantity from "../../common/MintQuantity";
import CheckoutCard from "./CheckoutCard";
import TxProgressCard from "./TxProgressCard";
import galaxisLogo from "../../../assets/images/galaxis.svg";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toast";
import { ethers } from "ethers";

const sx = {
  root: {
    position: "relative",
    background: "rgb(250,249,245)",
    width: "100%",
    height: "100%",
    zIndex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "top",
    textAlign: "center",
    gap: "0 25px",
    maxWidth: "350px",
    mx: "auto",
  },
  text: {
    fontFamily: "bau",
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "end",
  },
  logo: {
    position: "relative",
    height: "50px",
    mr: "auto",
    ml: 10,
    zIndex: 2,
    mt: 5,
    mb: 1,
  },
};

const GalaxisCheckout = ({
  setShow,
  setInProgress,
  setShowTermsAndConditions,
  price,
  saleStatus,
  availableMint,
  mintAmount,
}) => {
  const { address } = useWeb3Ctx();
  const [txInProgress, setTxInProgress] = useState(false);
  const [txEtherScan, setTxEtherScan] = useState(null);
  const [approveInProgress, setApproveInProgress] = useState(false);

  const whitelistSignature = useAllowList();
  const contract = useTheAssociationSalesContract();
  const history = useHistory();

  const isPresale = saleStatus == SALE_STATUS.PRESALE;
  const isSale = saleStatus == SALE_STATUS.SALE;

  useEffect(() => {
    if (setInProgress) {
      setInProgress(txInProgress);
    }
  }, [txInProgress]);

  const mintWhitelist = async () => {
    console.log("Mint white list");
    if (!whitelistSignature) {
      toast.error("Signature not found!");
      return;
    }

    setApproveInProgress(true);

    // Construct mint parameters
    let mint_free = "1";
    let max_mint = "1";
    let from = address;
    let start = "0";
    let end = "5555555555";
    let eth_price = "0"; //ethers.utils.parseUnits(price, "ether");
    let dust_price = "0";
    let signature = whitelistSignature;
    let structAgg = [
      mint_free,
      max_mint,
      from,
      start,
      end,
      eth_price,
      dust_price,
      signature,
    ];
    let infoData = structAgg;

    // console.log(infoData)

    await contract
      .mint_approved(infoData, 1, 0, {
        value: ethers.utils.parseEther(price.toString()),
      })
      .then((tx) => {
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_URL}${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_URL}${tx.hash}`);
        return tx.wait().then((receipt) => {
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Minted NFT");
            setTxEtherScan(null);

            history.replace({ ...history.location, state: null });
            history.push({
              pathname: "/wallet",
            });
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const mintSale = async (amount, price) => {
    console.log("Mint Sale!");

    setApproveInProgress(true);

    await contract
      .mint(amount, {
        value: ethers.utils.parseEther(price.toString()),
      })
      .then((tx) => {
        setApproveInProgress(false);
        setTxInProgress(true);
        console.log(`${config.ETHERSCAN_URL}${tx.hash}`);
        setTxEtherScan(`${config.ETHERSCAN_URL}${tx.hash}`);
        return tx.wait().then((receipt) => {
          if (receipt && receipt.status === 1) {
            toast.success("Successfully Minted NFT");
            setTxEtherScan(null);

            history.replace({ ...history.location, state: null });
            history.push({
              pathname: "/wallet",
            });
          } else {
            toast.error("Transaction Failed");
            setTxInProgress(false);
            setTxEtherScan(null);
          }
        });
      })
      .catch(handleError);

    setTxInProgress(false);
    setApproveInProgress(false);
  };

  const handleError = (e) => {
    console.error(e);
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

  return (
    <Box sx={sx.root}>
      <Box>
        <Box component="a" href="https://galaxis.xyz" target="_blank">
          <Box component="img" src={galaxisLogo} sx={sx.logo}></Box>
        </Box>
      </Box>

      <CheckoutCard
        header={"Checkout"}
        setOpen={setShow}
        isOpen={!txInProgress}
      >
        <Box sx={sx.content}>
          <Box
            sx={{
              display: "flex",
              width: "80%",
              mx: "auto",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "45%" }}>
              <Typography sx={sx.text}>
                The 2022 NBA Playoffs <br></br> Association NFT
              </Typography>
            </Box>
            <Box sx={{ width: "45%" }}>
              <Card xs={12} sm={12} md={12} />
            </Box>
          </Box>

          {isPresale && (
            <MintQuantity
              price={Number(price)}
              maxAmount={1}
              onClickMint={mintWhitelist}
              editable={false}
              setShowTermsAndConditions={setShowTermsAndConditions}
            />
          )}

          {isSale && (
            <MintQuantity
              price={Number(price)}
              maxAmount={availableMint}
              onClickMint={mintSale}
              mintAmount={mintAmount}
              editable={true}
              setShowTermsAndConditions={setShowTermsAndConditions}
            />
          )}
        </Box>
      </CheckoutCard>

      <BackdropModal isOpen={approveInProgress} />

      <TxProgressCard isOpen={txInProgress} txEtherScan={txEtherScan} />
    </Box>
  );
};

export default GalaxisCheckout;
