import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';
import { SALE_STATUS } from '../../hooks/useSaleInfo';
import useSaleInfo from '../../hooks/useSaleInfo';

import GalaxisCheckout from './components/GalaxisCheckout';
import GalaxisTems from './components/GalaxisTerms';
import Card from '../common/Card';
import Footer from '../Home/components/Footer';
// import Header from "./components/Header";
import backIcon from '../../assets/images/back-icon-black.svg';
import ethIcon from '../../assets/images/eth-black.svg';
import background from '../../assets/images/termsBackground.png';

import {
  Box,
  Grid,
  Typography,
  Button,
  Checkbox,
  Slide,
  Input,
} from '@mui/material';
import { keyframes } from '@mui/system';
import { toast } from 'react-toast';

const scrollRight = keyframes`
from   { transform: translateX(-100%) translateZ(0); }
to { transform: translateX(100%) translateZ(0); }
`;

const BP1 = '@media (max-width: 899px)';

const sx = {
  root: {
    position: 'relative',
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: `url(${background})`,
    backgroundColor: '#17408b',
    backgroundBlendMode: 'multiply',
  },
  header: {
    position: 'relative',
    background: '#c9082a',
    width: '100%',
    height: '160px',
  },
  headerText: {
    position: 'absolute',
    fontFamily: 'action-nba',
    lineHeight: '160px',
    fontSize: '160px',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    color: '#fff',
    animation: `${scrollRight} 40s infinite linear`,
    perspective: 1000,
    backfaceVisibility: 'hidden',
    px: '5px',
  },
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: '1440px',
    m: 'auto',
    mb: 3,
  },
  checkoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    p: 5,
  },
  animationDelay: {
    transform: 'translateX(-100%)',
    animationDelay: `20s`,
  },
  addressBtn: {
    py: '10px',
    px: '15px',
    color: '#fff',
    margin: 'auto',
    border: '2px solid #FFE500',
    background: '#000',
    '&:hover': {
      background: '#000',
    },
  },
  backBtn: {
    py: '10px',
    px: '15px',
    color: '#000',
    margin: 'auto',
    border: '2px solid #000',
    background: '#fff',
    '&:hover': {
      background: '#fff',
    },
  },
  mintBtn: {
    py: '10px',
    px: '15px',
    color: '#fff',
    my: 2,
    fontSize: '34px',
    border: '2px solid #FFE500',
    background: '#000',
    '&:hover': {
      background: '#000',
    },
  },
  backIcon: {
    height: '40%',
    pr: 1,
  },
  checkoutRow: {
    borderBottom: '1px solid rgba(0,0,0,0.8)',
    py: 2,
  },
  checkoutRowHeading: {
    fontSize: '24px',
  },
  checkoutRowText: {
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
  },
  checkoutRowText2: {
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'end',
  },
  amountContainer: {
    border: '1px solid rgba(0,0,0,0.8)',
    py: '10px',
    px: '15px',
    ml: 'auto',
    textAlign: 'center',
  },
  price: {
    fontSize: '22px',
    textAlign: 'end',
    '&:before': {
      content: '""',
      display: 'inline-block',
      background: `url(${ethIcon}) no-repeat`,
      width: '18px',
      height: '18px',
      backgroundSize: 'contain',
    },
  },
  checkboxContainer: {
    fontFamily: 'roboto-mono',
    fontSize: '12px',
    lineHeight: '14px',
    mt: 1,
    py: 2,
  },
  checkbox: {
    margin: '0',
    color: '#000',
    '&.Mui-checked': {
      color: '#000',
    },
    padding: '0',
    fontSize: '30px',
    mr: 1,
    mb: '3px',
  },
  galaxisCheckoutContainer: {
    // display: "flex",
    // flexDirection: "column",
    // flexGrow: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
  },
  mintInput: {
    color: '#000',
    fontSize: 14,
    width: 10,
    minWidth: 20,
    maxHeight: 36,
    height: 36,
    textAlign: 'center',
    textAlignLast: 'center',
    padding: 0,
    borderTop: 0,
    borderBottom: 0,
    '&:before, &:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none',
      },
    '& input': {
      padding: 0,
    },
  },
  mobileView: {
    display: 'none',
    [BP1]: {
      display: 'block',
    },
  },
  webView: {
    [BP1]: {
      display: 'none',
    },
  },
};

const ScrollingHeader = ({ children }) => {
  return (
    <Box sx={sx.header}>
      <Typography sx={sx.headerText}>{children}</Typography>
      <Typography sx={{ ...sx.headerText, ...sx.animationDelay }}>
        {children}
      </Typography>
    </Box>
  );
};

const MintInput = ({ min, max, value, onChange }) => {
  return (
    <Input
      type="number"
      sx={{ ...sx.mintInput }}
      value={value}
      disableUnderline={true}
      onChange={(e) => {
        const value = Number(e.target.value);
        if (value) {
          if (min != null && value < min) {
            onChange(min);
            e.target.value = min;
          } else if (max != null && value > max) {
            onChange(max);
            e.target.value = max;
            toast.error('You have reached the mint limit for Public Mint');
          } else {
            onChange(value);
            e.target.value = value;
          }
        } else {
          onChange(0);
          e.target.value = 0;
        }
      }}
    ></Input>
  );
};

const MintPage = () => {
  const { address } = useWeb3Ctx();
  const [checked, setChecked] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [showGalaxisCheckout, setShowGalaxisCheckout] = useState(false);
  const [showMoonpayCheckout, setShowMoonpayCheckout] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [maxAmountMint, setMaxAmountMint] = useState(1);
  const [availableMint, setAvailableMint] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);

  const history = useHistory();

  const {
    price,
    saleStatus,
    mintAvailableSale,
    mintAvailableWhitelist,
    maxMintSale,
    maxMintWhitelist,
  } = useSaleInfo();

  useEffect(() => {
    if (price != null) {
      setTotalPrice(parseFloat((price * mintAmount).toFixed(3)));
    }
  }, [mintAmount, price]);

  useEffect(() => {
    if (saleStatus == SALE_STATUS.PRESALE) {
      setMaxAmountMint(maxMintWhitelist);
      setAvailableMint(mintAvailableWhitelist);
    } else if (saleStatus == SALE_STATUS.SALE) {
      setMaxAmountMint(maxMintSale);
      setAvailableMint(mintAvailableSale);
      setMintAmount(mintAvailableSale);
    }
  }, [saleStatus]);

  useEffect(() => {
    if (!address) {
      history.push({
        pathname: '/',
      });
    }
  }, [address]);

  const goBack = () => {
    history.push({
      pathname: '/',
    });
  };

  const mintMoonpay = () => {
    if (checkMintLimit()) {
      setShowMoonpayCheckout(true);
    }
  };

  const mintGalaxis = () => {
    if (checkMintLimit()) {
      setShowGalaxisCheckout(true);
    }
  };

  const checkMintLimit = () => {
    if (saleStatus == SALE_STATUS.SALE) {
      if (!mintAvailableSale) {
        toast.error('You have reached the mint limit for Public Mint');
        return false;
      }
    } else if (saleStatus == SALE_STATUS.PRESALE) {
      if (!mintAvailableWhitelist) {
        toast.error('You have reached the mint limit for Allow List');
        return false;
      }
    }
    return true;
  };

  const disableMintBtn =
    (saleStatus != SALE_STATUS.SALE && saleStatus != SALE_STATUS.PRESALE) ||
    mintAmount < 1;

  return (
    <Box sx={sx.root}>
      {!txInProgress && (
        <ScrollingHeader>
          MINT MINT MINT MINT MINT MINT MINT MINT MINT MINT MINT MINT MINT MINT
        </ScrollingHeader>
      )}
      {txInProgress && (
        <ScrollingHeader>
          LOADING LOADING LOADING LOADING LOADING LOADING LOADING LOADING
          LOADING
        </ScrollingHeader>
      )}

      {!showGalaxisCheckout && (
        <Box sx={sx.container}>
          <Grid container sx={{ px: 5, pb: 3, pt: 5 }}>
            <Grid item xs={6}>
              <Button
                variant="contained2"
                sx={sx.backBtn}
                onClick={() => goBack()}
              >
                <Box sx={sx.backIcon} component="img" src={backIcon}></Box> GO
                BACK
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'end' }}>
              <Button variant="contained2" sx={sx.addressBtn}>
                {address &&
                  address.substr(0, 6) +
                    '...' +
                    address.substr(address.length - 4, 4)}
              </Button>
            </Grid>
          </Grid>

          <Grid container sx={{ mb: 'auto', px: 5, py: 2 }}>
            <Grid item xs={0} md={6} sx={{ textAlign: 'left' }}>
              <Card
                xs={11}
                sm={11}
                md={11}
                rootStyle={{ ml: 0, ...sx.webView }}
                background={'#f9e685'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={sx.checkoutContainer}>
                <Card
                  xs={11}
                  sm={11}
                  md={11}
                  rootStyle={{ ml: 0, mb: 5, ...sx.mobileView }}
                  background={'#f9e685'}
                />
                <Typography variant="heading1" sx={{ mb: 3 }}>
                  The 2022 NBA Playoffs Association NFT
                </Typography>
                <Typography sx={{ mb: 3 }}>
                  Dynamic NFTs that change their appearance based on team and
                  player on-court performance during the 2022 NBA Playoffs.
                  <br></br> <br></br>
                  The Association's 2022 NBA Playoffs dynamic NFTs are being
                  issued in a blind-mint, meaning that you will not be able to
                  know which 2022 NBA Playoffs player you are minting prior to
                  purchase.
                  <br></br> <br></br>
                  Your player will be revealed prior to the start of the 2022
                  NBA Playoffs.
                  <br></br> <br></br>
                  Everyone has a fair and equal chance of minting any player
                  from any of the 16 Playoff teams.
                  <br></br> <br></br>
                  Only {maxAmountMint} NFT can be minted per wallet.
                </Typography>

                <Grid container sx={sx.checkoutRow}>
                  <Grid item xs={5} sm={4}>
                    <Typography variant="heading1" sx={sx.checkoutRowHeading}>
                      QUANTITY
                    </Typography>
                  </Grid>
                  <Grid item xs={5} sm={6} sx={{ alignSelf: 'center' }}>
                    <Typography
                      sx={{ ...sx.checkoutRowText, textAlign: 'center' }}
                    >
                      ({maxAmountMint} max per wallet address)
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ display: 'flex' }}>
                    {saleStatus == SALE_STATUS.SALE ? (
                      <Box sx={sx.amountContainer}>
                        <MintInput
                          min={0}
                          max={mintAvailableSale}
                          value={mintAmount}
                          onChange={(value) => setMintAmount(value)}
                        />
                      </Box>
                    ) : (
                      <Box sx={sx.amountContainer}>{maxAmountMint}</Box>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={sx.checkoutRow}>
                  <Grid item xs={4}>
                    <Typography variant="heading1" sx={sx.checkoutRowHeading}>
                      TOTAL
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{ alignText: 'end', alignSelf: 'center' }}
                  >
                    <Box>
                      <Typography sx={sx.price}>
                        {totalPrice.toFixed(2)}
                      </Typography>
                    </Box>
                    <Typography sx={sx.checkoutRowText2}>
                      (not including gas fees)
                    </Typography>
                  </Grid>
                </Grid>

                {/* <Box sx={sx.checkboxContainer}>
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                    sx={sx.checkbox}
                  ></Checkbox>
                  I agree to the Terms & Conditions
                </Box> */}
                {/*
                <Button
                  variant="contained2"
                  sx={sx.mintBtn}
                  onClick={() => mintMoonpay()}
                  disabled={!checked || disableMintBtn}
                >
                  MINT WITH CREDIT CARD
                </Button> */}
                <Button
                  variant="contained2"
                  sx={sx.mintBtn}
                  onClick={() => mintGalaxis()}
                  disabled={disableMintBtn}
                >
                  CHECKOUT
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Footer />

      <Slide direction="up" in={showGalaxisCheckout} mountOnEnter unmountOnExit>
        <Box sx={sx.galaxisCheckoutContainer}>
          <GalaxisCheckout
            setShow={setShowGalaxisCheckout}
            setInProgress={setTxInProgress}
            setShowTermsAndConditions={setShowTermsAndConditions}
            price={price}
            saleStatus={saleStatus}
            availableMint={availableMint}
            mintAmount={mintAmount}
          />
        </Box>
      </Slide>
      <Slide
        direction="up"
        in={showTermsAndConditions}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={{ ...sx.galaxisCheckoutContainer, zIndex: 101 }}>
          <GalaxisTems setShow={setShowTermsAndConditions} />
        </Box>
      </Slide>
    </Box>
  );
};

export default MintPage;
