export const loadNext = (
  nfts,
  currentPageRef,
  setCurrentPage,
  setCards,
  cards
) => {
  // let tmpArray = currentPageRef.current === 1 ? [] : cards;
  // console.log(currentPageRef.current, 'cpref');
  // console.log(tmpArray, 'tmpARray befor push');
  // console.log(cards.length, 'cards.length');
  for (let index = 0; index < nfts.length; index++) {
    // tmpArray.push(nfts[index]);
    setCards((oldArray) => [...oldArray, nfts[index]]);
  }
  // console.log(tmpArray, 'tmpARray after push');
  // setCards(tmpArray);
  // console.log('loadnext meghivv');
};
